import {Component, Inject} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FilialiPaginatorService} from '../../../services/paginators/filiali-paginator.service';
import {Filiale, filialeStringifier} from '../../../models/entities/filiale';
import {UserService} from '../../../services/user.service';
import * as moment from "moment";
import {FilialiPaPaginatorService} from '../../../services/paginators/filiali-pa-paginator.service';

@Component({
  selector: 'app-filiale-and-date-selector-dialog',
  templateUrl: './filiale-and-data-selector-dialog.component.html',
  styleUrls: ['./filiale-and-data-selector-dialog.component.scss']
})
export class FilialeAndDataSelectorDialogComponent {
  title: string;
  filialeAccetta: Filiale;
  dataRitiro: Date;
  // public formGroup: FormGroup;
  public filialePaginator: FilialiPaPaginatorService;
  formGroup = new FormGroup({
    data_ritiro: new FormControl('', Validators.required),
    filialeAccetta: new FormControl('', Validators.required),
  });
  constructor(private _dialogRef: MatDialogRef<FilialeAndDataSelectorDialogComponent>,
              private _formBuilder: FormBuilder,
              public filialiPaginatorService: FilialiPaginatorService,
              public userService: UserService,
              @Inject(MAT_DIALOG_DATA) public data: Payload) {
    if (data && data.title) {
      this.title = data.title;
    } else {
      this.title = 'Seleziona una data e una filiale';
    }
    // this.formGroup = this._formBuilder.group({
    //   dataRitiro: [moment(this.dataRitiro).isValid() ? moment(this.dataRitiro) : moment()],
    //   filialeAccetta: [this.filialeAccetta ?? true, [Validators.required]],
    // });
  }

  filialeStringifier = filialeStringifier;

  public isValid(): boolean {
    console.warn(this.dataRitiro);
    console.warn(this.formGroup);
    return this.filialeAccetta !== null && this.filialeAccetta  !== undefined && this.formGroup.value.dataRitiro !== null && this.formGroup.value.dataRitiro !== undefined;
  }

  public onConfirmButtonClicked(): void {
    if (this.isValid()) {
      return;
    }
    const body = this.formGroup.value;
    body.dataRitiro = moment(body.dataRitiro).format('YYYY-MM-DD HH:mm:ss');
    body.filialeAccetta = this.filialeAccetta;


    // this._dialogRef.close(filialeAndData);
    this._dialogRef.close(body);
  }
}

export interface Payload {
  title: string;
}
