import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../../environments/environment';
import {SimpleAlertDialogComponent} from '../simple-alert-dialog/simple-alert-dialog.component';
import {AppInjectorService} from '../../../services/app-injector.service';

@Component({
  selector: 'app-add-files-importer-pdf-dialog',
  templateUrl: './add-files-importer-pdf-dialog.component.html',
  styleUrls: ['./add-files-importer-pdf-dialog.component.scss']
})
export class AddFilesImporterPdfDialogComponent {
  public files: File[] = [];
  public isLoading = new BehaviorSubject<boolean>(false);
  public maxSize = 0;
  public id_file_du;
  protected _dialog: MatDialog;

  constructor(@Inject(MAT_DIALOG_DATA) public data: Payload,
              private _dialogRef: MatDialogRef<AddFilesImporterPdfDialogComponent>,
              private _http: HttpClient
              ) {
    this.maxSize = this.data.maxSize ? this.data.maxSize : 30000 * 1024;
    this._dialog = AppInjectorService.injector.get(MatDialog);
    this.id_file_du = this.data.id_file_du;
  }

  public async onConfirmButtonClicked(): Promise<void> {
    this.isLoading.next(true);
    let promises = [];

    const res = [];
    for (const file of this.files) {
      const formData = new FormData();
      formData.append('files', file);
      formData.append('id_file_du', this.id_file_du);
      promises.push(this._http.post(`${environment.nodeUrl}accettazione/upload_pdf`,
        formData,
        ) .toPromise()
      );
      // const response = await this._http.post(`${environment.baseUrl}api/sdoc/importazione/uploadfileimportare`, formData).toPromise();
      // res.push(response);
    }
    if (promises.length > 0){
      const parzres = await Promise.all(promises);
      res.concat(... parzres);
    }
    // const res = await Promise.all(promises);
    let index = 0;
    for (const singleres of res){
      index++;
      console.log('res ' + index, singleres);
      if (singleres.data && singleres.data.nomefile && singleres.data.file_gia_importato){
        const datiimportato = singleres.data.file_gia_importato;
        const result = await this._dialog.open(SimpleAlertDialogComponent, {
          data: {
            title: 'Controllo file già importati',
            content: 'ATTENZIONE: un file con lo stesso nome "' + singleres.data.nomefile + '"<br>è già stato importato n.' + datiimportato.length
              + ' volt' + (datiimportato.length === 1 ? 'a' : 'e') + ',<br>l\'ultima nel lotto ' + datiimportato[0].lotto_progressivo
              + ' dell\'appalto "' + datiimportato[0].lotto_app_cod + '".<br>Controllate per non importarlo più volte.',
            actions: [{
              label: 'OK',
              primary: true,
              result: () => true
            }]
          }
        }).afterClosed().toPromise();
      }
    }
    console.log('res upload files', res);
    this.isLoading.next(false);
    this._dialogRef.close(true);
  }

  private _delay(ms: number): Promise<any> {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
}



export interface Payload {
  maxSize: number;
  id_file_du: number;
}
