import {Component, OnDestroy, OnInit} from '@angular/core';
import {Operazione, OperazioneDettaglio} from '../../../models/entities/operazione';
import {FuseProgressBarService} from '../../../../../../@fuse/components/progress-bar/progress-bar.service';
import {ActivatedRoute} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {OperazioneDettaglioDataDialogComponent} from '../../dialogs/operazione-dettaglio-data-dialog/operazione-dettaglio-data-dialog.component';
import {EchoService} from 'ngx-laravel-echo';
import {UserService} from '../../../services/user.service';
import {Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {OperazioniApiService} from '../../../services/api/operazioni-api.service';

@Component({
  selector: 'app-operazione',
  templateUrl: './operazione.component.html',
  styleUrls: ['./operazione.component.scss']
})
export class OperazioneComponent implements OnInit, OnDestroy {
  private _subscription: Subscription;
  private _unsubscribeAll: Subject<any>;
  public displayedColumns = ['sottotipo', 'messaggio', 'dtora_inizio', 'dtora_fine', 'stato', 'actions'];
  public operazione: Operazione;

  constructor(private _dialog: MatDialog,
              private _echoService: EchoService,
              private _fuseProgressBarService: FuseProgressBarService,
              private _operazioniApiService: OperazioniApiService,
              private _route: ActivatedRoute,
              private _userService: UserService) {
  }

  async ngOnInit(): Promise<void> {
    this._unsubscribeAll = new Subject();
    this._route.params.pipe(takeUntil(this._unsubscribeAll)).subscribe(async params => {
      this._fuseProgressBarService.show();
      this.operazione = await this._operazioniApiService.get(params['id']);
      this._fuseProgressBarService.hide();

      this._subscription = this._echoService.listen(`user.${this._userService.user.id}`, '.operazioni').subscribe(event => {
        if (event.operazione.id === this.operazione.id) {
          this.operazione = event.operazione;
        }
      });
    });
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public onShowDataButtonClicked(operazione: OperazioneDettaglio): void {
    this._dialog.open(OperazioneDettaglioDataDialogComponent, {data: operazione});
  }
}
