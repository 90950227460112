import { Injectable } from '@angular/core';
import { Paginator } from '../../models/paginator';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Manutenzione } from '../../models/entities/manutenzione';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ManutenzionePaginatorService extends Paginator<Manutenzione> {
  public includes = ['id', 'db', 'table_name','table_rows','prod_num_rows', 'last_updated', 'status', 'where', 'actions'];

  constructor(private http: HttpClient) {
    super(http, `${environment.nodeUrl}api/manutenzione`);
  }

  public updateStatus(recordId: number, newStatus: number): void {
    const record = this.page.value.data.find(item => item.id === recordId);
    if (record) {
      record.status = newStatus;
      record.enableUpdate = false
      record.last_updated = new Date()
    }
  }
  public filterData(filters?: any): void {
    console.log('filterData', filters);

    let params = new HttpParams()
       filters.forEach(obj => {
        params = params.set(obj.column, obj.search);
      });

    this.http.get<any>(`${environment.nodeUrl}api/manutenzione-filter`, { params }).subscribe((response) => {
      this.page.next(response);

    })
  }
}
