import {NgModule} from '@angular/core';
import {FuseSharedModule} from '../../../@fuse/shared.module';
import {LoginComponent} from './components/pages/login/login.component';
import {environment} from '../../../environments/environment';
import {OAuthModule, OAuthStorage} from 'angular-oauth2-oidc';
import {UsersComponent} from './components/pages/users/users.component';
import {ClientiComponent} from './components/pages/clienti/clienti.component';
import {FilialiComponent} from './components/pages/filiali/filiali.component';
import {MaterialModule} from './material.module';
import {RoutingModule} from './routing.module';
import {SystemsComponent} from './components/pages/systems/systems.component';
import {FuseSidebarModule} from '../../../@fuse/components';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ClienteComponent} from './components/pages/cliente/cliente.component';
import {FilialeComponent} from './components/pages/filiale/filiale.component';
import {UserComponent} from './components/pages/user/user.component';
import {ToastrModule} from 'ngx-toastr';
import {NgxLaravelEchoModule} from 'ngx-laravel-echo';
import {SplashComponent} from './components/pages/splash/splash.component';
import {MultiEntitySelectorDialogComponent} from './components/dialogs/multi-entity-selector-dialog/multi-entity-selector-dialog.component';
import {CommonComponentsModule} from './common-components.module';
import {SimpleAlertDialogComponent} from './components/dialogs/simple-alert-dialog/simple-alert-dialog.component';
import {ValidationDialogComponent} from './components/dialogs/validation-dialog/validation-dialog.component';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {HttpErrorsInterceptor} from './interceptors/http-errors.interceptor';
import {OperazioneComponent} from './components/pages/operazione/operazione.component';
import {OperazioniComponent} from './components/pages/operazioni/operazioni.component';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {OperazioneDettaglioDataDialogComponent} from './components/dialogs/operazione-dettaglio-data-dialog/operazione-dettaglio-data-dialog.component';
import {OperazioneDettaglioDataValueComponent} from './components/partials/operazione-dettaglio-data-value/operazione-dettaglio-data-value.component';
import {DatepickerDialogComponent} from './components/dialogs/datepicker-dialog/datepicker-dialog.component';
import {PortalettereComponent} from './components/pages/portalettere/portalettere.component';
import {PortalettereIndexComponent} from './components/pages/portalettere-index/portalettere-index.component';
import {CambioPasswordComponent} from './components/pages/cambio-password/cambio-password.component';
import {FormErrorInspectComponent} from './components/partials/form-error-inspect/form-error-inspect.component';
import {FilialiMultiSelectorComponent} from './components/partials/filiali-multi-selector/filiali-multi-selector.component';
import {MultiFilialeSelectorDialogComponent} from './components/dialogs/multi-filiale-selector-dialog/multi-filiale-selector-dialog.component';
import { ManutenzioneComponent } from './components/pages/manutenzione/manutenzione.component';
import {DatetimepickerDialogComponent} from "./components/dialogs/datetimepicker-dialog/datetimepicker-dialog.component";
import {NgxMatDatetimePickerModule} from "@angular-material-components/datetime-picker";
import {AddFilesImporterPdfDialogComponent} from "./components/dialogs/add-files-importer-pdf-dialog/add-files-importer-pdf-dialog.component";
import { RistampaComponent } from './components/pages/ristampa/ristampa.component';
import { RistampaService } from './services/api/ristampa-api.service';
import { RistampaDialogComponent } from './components/dialogs/ristampa/ristampa-dialog.component';
import { AddFilesImporterPdfDialogComponentMultiple } from './components/dialogs/add-files-importer-pdf-dialog-massive/add-files-importer-pdf-dialog.component';

@NgModule({
  declarations: [
    ClienteComponent,
    ClientiComponent,
    DatepickerDialogComponent,
    DatetimepickerDialogComponent,
    AddFilesImporterPdfDialogComponent,
    AddFilesImporterPdfDialogComponentMultiple,
    FilialeComponent,
    FilialiComponent,
    LoginComponent,
    MultiEntitySelectorDialogComponent,
    OperazioneComponent,
    OperazioniComponent,
    OperazioneDettaglioDataDialogComponent,
    OperazioneDettaglioDataValueComponent,
    SimpleAlertDialogComponent,
    SplashComponent,
    SystemsComponent,
    UserComponent,
    UsersComponent,
    ManutenzioneComponent,
    ValidationDialogComponent,
    PortalettereComponent,
    PortalettereIndexComponent,
    CambioPasswordComponent,
    FormErrorInspectComponent,
    MultiFilialeSelectorDialogComponent,
    RistampaComponent,
    RistampaDialogComponent
  ],
  imports: [
    BrowserAnimationsModule,
    FuseSharedModule,
    FuseSidebarModule,
    MaterialModule,
    NgxLaravelEchoModule.forRoot({
      userModel: 'user',
      notificationNamespace: null,
      options: {
        broadcaster: 'pusher',
        authEndpoint: `${environment.baseUrl}broadcasting/auth`,
        wsHost: environment.echoHost,
        wssPort: environment.echoPort,
        key: environment.echoKey,
        disableStats: true,
        enabledTransports: ['ws', 'wss'],
        forceTLS: environment.echoForceTls,
      }
    }),
    NgxWebstorageModule.forRoot(),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.baseUrl, environment.nodeUrl],
        sendAccessToken: true
      }
    }),
    RoutingModule,
    ToastrModule.forRoot({enableHtml: true, positionClass: 'toast-bottom-right', timeOut: 10000}),

    CommonComponentsModule,
    NgxMatDatetimePickerModule,
  ],
  exports: [
    FilialiMultiSelectorComponent,
    RistampaDialogComponent
  ],
  providers: [
    {provide: OAuthStorage, useValue: localStorage},
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorsInterceptor, multi: true},
    RistampaService
  ]
})
export class CommonModule {
}
