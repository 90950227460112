import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';
import {ToastrService} from 'ngx-toastr';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-file-dropper',
  templateUrl: './file-dropper.component.html',
  styleUrls: ['./file-dropper.component.scss']
})
export class FileDropperComponent implements OnInit, OnDestroy {


  public files: File[] = [];

  @Input()
  public clear = new BehaviorSubject<boolean>(false);

  @Input()
  public multiple = true;

  @Input()
  public label: string;

  @Input()
  public filters: string[];

  @Input()
  public maxSize: number = 2048 * 1024;

  @Output()
  public fileSelected: EventEmitter<File[]> = new EventEmitter();

  @Output()
  public fileChanged: EventEmitter<File[]> = new EventEmitter();

  constructor(private toastrService: ToastrService) {

  }

  ngOnInit(): void {
    this.clear.subscribe(clearFiles => {
      if (clearFiles) {
        this.files = [];
      }
    });
  }

  public onFileDropped(files: NgxFileDropEntry[]): void {
    if (!this.multiple) {
      const firstFile = files[0];
      files = [];
      this.files = [];
      files.push(firstFile);
    }
    for (const ngxFile of files) {
      if (ngxFile.fileEntry.isFile) {
        (ngxFile.fileEntry as FileSystemFileEntry).file(file => {
          const fileExists = this.files.filter(fileUploaded => (fileUploaded.name === file.name));
          if (fileExists.length > 0) {
            return;
          }
          const ext = file.name.substring(file.name.lastIndexOf('.') + 1);
          if (this.filters && !this.filters.includes('.' + ext.toLowerCase())) {
            this.toastrService.warning(file.name + ' Estensione non valida', 'Attenzione!');
            return;
          }
          if (this.maxSize < file.size) {
            this.toastrService.warning(file.name + ' Troppo grande', 'Attenzione!');
            return;
          }
          this.files.push(file);
          if (files.indexOf(ngxFile) === files.length - 1) {
            this.fileSelected.emit(this.files);
          }
        });
      }
    }
    // this.fileSelected.emit(this.files);
  }

  public onFileRemoved(): void {
    for (const file of this.files) {
      this.files.splice(this.files.indexOf(file), 1);
    }
    this.fileChanged.emit(this.files);
  }

  ngOnDestroy(): void {
    this.clear.next(false);
    this.clear.complete();
  }
}
