import {Injectable} from '@angular/core';
import {Paginator} from '../../models/paginator';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {Portalettere} from '../../models/entities/portalettere';

@Injectable({
  providedIn: 'root'
})
export class PortaletterePaginatorService extends Paginator<Portalettere> {
  public includes = ['filialeCentral', 'corso'];

  constructor(http: HttpClient) {
    super(http, `${environment.baseUrl}api/portalettere`);
  }
}
