<h1 mat-dialog-title>{{data.title}}</h1>
<mat-dialog-content [innerHTML]="data.content"></mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end" fxLayoutGap="16px">
  <!--
  <ng-container *ngIf="!(data?.actions)">
    <button mat-flat-button mat-dialog-close>Annulla</button>
    <button mat-raised-button [mat-dialog-close]="true" color="accent">Conferma</button>
  </ng-container>
  -->
  <ng-container *ngFor="let action of data.actions">
    <button *ngIf="action.primary" mat-raised-button [mat-dialog-close]="action.result()"
            color="accent">{{action.label}}</button>
    <button *ngIf="!action.primary" mat-button [mat-dialog-close]="action.result()">{{action.label}}</button>
  </ng-container>
</mat-dialog-actions>
