import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EntitySelectorComponent} from './components/partials/entity-selector/entity-selector.component';
import {FiltersComponent} from './components/partials/filters/filters.component';
import {MultiEntitySelectorComponent} from './components/partials/multi-entity-selector/multi-entity-selector.component';
import {NotificationsComponent} from './components/partials/notifications/notifications.component';
import {PaginatorComponent} from './components/partials/paginator/paginator.component';
import {CustomPaginatorComponent} from './components/partials/custom-paginator/custom-paginator.component';
import {MomentPipe} from './pipes/moment.pipe';
import {MaterialModule} from './material.module';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {FuseSharedModule} from '../../../@fuse/shared.module';
import {MultiEntitySelectorDialogComponent} from './components/dialogs/multi-entity-selector-dialog/multi-entity-selector-dialog.component';
import {SimpleAlertDialogComponent} from './components/dialogs/simple-alert-dialog/simple-alert-dialog.component';
import {FileDropperComponent} from './components/partials/file-dropper/file-dropper.component';
import {NgxFileDropModule} from 'ngx-file-drop';
import {ValidationDialogComponent} from './components/dialogs/validation-dialog/validation-dialog.component';
import {OperazioniComponent} from './components/partials/operazioni/operazioni.component';
import {RouterModule} from '@angular/router';
import {OperazioneDettaglioDataDialogComponent} from './components/dialogs/operazione-dettaglio-data-dialog/operazione-dettaglio-data-dialog.component';
import {DatepickerDialogComponent} from './components/dialogs/datepicker-dialog/datepicker-dialog.component';
import {DatetimepickerDialogComponent} from './components/dialogs/datetimepicker-dialog/datetimepicker-dialog.component';
import {InputDialogComponent} from './components/dialogs/input-dialog/input-dialog.component';
import {FilialeAndDataSelectorDialogComponent} from './components/dialogs/filiale-and-data-selector-dialog/filiale-and-data-selector-dialog.component';
import {ArPipe} from './pipes/ar.pipe';
import {TruncateTextPipe} from './pipes/truncate-text.pipe';
import {FilialiMultiSelectorComponent} from './components/partials/filiali-multi-selector/filiali-multi-selector.component';
import {AddFilesImporterPdfDialogComponent} from "./components/dialogs/add-files-importer-pdf-dialog/add-files-importer-pdf-dialog.component";
import { AddFilesImporterPdfDialogComponentMultiple } from './components/dialogs/add-files-importer-pdf-dialog-massive/add-files-importer-pdf-dialog.component';

@NgModule({
  declarations: [
    // Components
    EntitySelectorComponent,
    FileDropperComponent,
    FiltersComponent,
    MultiEntitySelectorComponent,
    NotificationsComponent,
    OperazioniComponent,
    PaginatorComponent,
    CustomPaginatorComponent,
    FilialiMultiSelectorComponent,

    // Pipes
    MomentPipe,
    ArPipe,
    TruncateTextPipe,
  ],
  entryComponents: [
    DatepickerDialogComponent,
    DatetimepickerDialogComponent,
    AddFilesImporterPdfDialogComponent,
    AddFilesImporterPdfDialogComponentMultiple,
    MultiEntitySelectorDialogComponent,
    OperazioneDettaglioDataDialogComponent,
    SimpleAlertDialogComponent,
    ValidationDialogComponent,
    InputDialogComponent,
    FilialeAndDataSelectorDialogComponent
  ],
  exports: [
    // Components
    EntitySelectorComponent,
    FileDropperComponent,
    FiltersComponent,
    MultiEntitySelectorComponent,
    NotificationsComponent,
    OperazioniComponent,
    PaginatorComponent,
    CustomPaginatorComponent,
    FilialiMultiSelectorComponent,

    // Pipes
    MomentPipe,
    ArPipe,
    TruncateTextPipe,
  ],
  imports: [
    CommonModule,
    InfiniteScrollModule,
    FuseSharedModule,
    MaterialModule,
    NgxFileDropModule,
    RouterModule,
  ]
})
export class CommonComponentsModule {
}
