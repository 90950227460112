<mat-form-field *ngIf="mode === 'autocomplete'" appearance="outline">
  <mat-label>{{label}}</mat-label>
  <input matInput type="text" [formControl]="formControl" [matAutocomplete]="autoComplete" [required]="required">
  <mat-progress-spinner *ngIf="paginator?.isLoading | async" matsuffix mode="indeterminate" color="accent" diameter="18"></mat-progress-spinner>
  <button *ngIf="!disabled && !(paginator?.isLoading | async) && formControl.value" mat-icon-button matSuffix (click)="clear()"><mat-icon>clear</mat-icon></button>
  <mat-autocomplete #autoComplete="matAutocomplete" (optionSelected)="onOptionSelected($event)">
    <mat-option *ngFor="let entity of (paginator?.page | async)?.data" [value]="entity">
      {{entityStringifier(entity)}}
    </mat-option>
  </mat-autocomplete>
  <mat-error>Questo campo è obbligatorio</mat-error>
</mat-form-field>

<mat-form-field *ngIf="mode === 'select'" appearance="outline">
  <mat-label>{{label}}</mat-label>
  <mat-progress-spinner *ngIf="paginator?.isLoading | async" matsuffix mode="indeterminate" color="accent" diameter="18"></mat-progress-spinner>
  <mat-select [disabled]="disabled" [compareWith]="compareEntity" [required]="required" [(value)]="value">
    <mat-option *ngFor="let entity of selectArray" [value]="entity">
      {{entityStringifier(entity)}}
    </mat-option>
  </mat-select>
  <mat-error>Questo campo è obbligatorio</mat-error>
</mat-form-field>
