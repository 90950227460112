<div class="page-layout simple fullwidth">

  <!-- HEADER -->
  <div class="header px-64 py-24" fxLayout="row" fxLayoutAlign="space-between center">
    <h2 class="m-0">{{filiale ? filiale.fil_nome.toUpperCase() : 'Nuova filiale'}}</h2>

    <button mat-raised-button class="action-button" color="accent" (click)="onSaveButtonClicked()"
            [disabled]="!formGroup || formGroup.invalid || !oneGestioneSelected() || (_filialiApiService.isLoading | async)">
      <mat-icon>save</mat-icon>
      SALVA
    </button>

    <!--
    <button mat-raised-button class="action-button" color="accent" (click)="getFormValidationErrors()">errori</button>
    -->
  </div>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <form *ngIf="formGroup" class="content px-64 py-24" fusePerfectScrollbar [formGroup]="formGroup" fxLayout="column">

    <!-- Informazioni generali -->
    <div class="fuse-card auto-width">
      <div class="p-24" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="mat-title m-0">Informazioni generali</span>

        <mat-checkbox formControlName="fil_attiva">Attiva</mat-checkbox>
      </div>

      <div class="p-24">
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-form-field appearance="outline" fxFlex="50" class="mr-8">
            <mat-label>Nome</mat-label>
            <input required matInput formControlName="fil_nome" maxlength="20">
            <mat-error>Questo campo è obbligatorio.</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="50" class="ml-8">
            <mat-label>Ragione Sociale</mat-label>
            <input required matInput formControlName="fil_descrizione" maxlength="50">
            <mat-error>Questo campo è obbligatorio.</mat-error>
          </mat-form-field>
        </div>

        <div class="mt-16" fxLayout="row" fxLayoutAlign="start center">
          <mat-form-field appearance="outline" fxFlex="80" class="mr-8">
            <mat-label>Indirizzo</mat-label>
            <input required matInput formControlName="fil_indirizzo" maxlength="50">
            <mat-error>Questo campo è obbligatorio.</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="20" class="ml-8">
            <mat-label>CAP</mat-label>
            <input required matInput formControlName="fil_cap" maxlength="5">
            <mat-error>Questo campo è obbligatorio.</mat-error>
          </mat-form-field>
        </div>

        <div class="mt-16" fxLayout="row" fxLayoutAlign="start center">
          <mat-form-field appearance="outline" fxFlex="80" class="mr-8">
            <mat-label>Località</mat-label>
            <input required matInput formControlName="fil_localita" maxlength="30">
            <mat-error>Questo campo è obbligatorio.</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="20" class="ml-8">
            <mat-label>Provincia</mat-label>
            <input required matInput formControlName="fil_provincia" maxlength="2">
            <mat-error>Questo campo è obbligatorio.</mat-error>
          </mat-form-field>
        </div>

        <div class="mt-16" fxLayout="row" fxLayoutAlign="start center">
          <mat-form-field appearance="outline" fxFlex="50" class="mr-8">
            <mat-label>Email</mat-label>
            <input matInput type="email" formControlName="fil_email" maxlength="50">
            <mat-error>Email non valida</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="50" class="ml-8">
            <mat-label>Email 2</mat-label>
            <input matInput type="email" formControlName="fil_email2" maxlength="50">
            <mat-error>Email non valida</mat-error>
          </mat-form-field>
        </div>

        <div class="mt-16" fxLayout="row" fxLayoutAlign="start center">
          <mat-form-field appearance="outline" fxFlex="33">
            <mat-label>Partita IVA</mat-label>
            <input matInput formControlName="fil_piva" maxlength="16">
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="34" class="mx-16">
            <mat-label>Codice cliente ARCA</mat-label>
            <input matInput formControlName="fil_codcliarca" maxlength="7">
            <mat-error *ngIf="formGroup.controls['fil_codcliarca'].hasError('pattern')">Il codice cliente ARCA deve iniziare per C, seguito da 6 numeri o CNONFAT</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="33">
            <mat-label>Codice fornitore ARCA</mat-label>
            <input required matInput formControlName="fil_codforarca" maxlength="7">
            <mat-error *ngIf="formGroup.controls['fil_codforarca'].hasError('pattern')">Il codice fornitore ARCA è obbligatiorio e deve iniziare per F, seguito da 6 numeri o FNONFAT</mat-error>
          </mat-form-field>
        </div>

        <div *ngIf="filiale" class="mt-16" fxLayout="row" fxLayoutAlign="start center">
          <mat-form-field appearance="outline" fxFlex="50" class="mr-8">
            <mat-label>Client ID</mat-label>
            <input matInput formControlName="fil_client_id">
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="50" class="ml-8">
            <mat-label>Client secret</mat-label>
            <input matInput formControlName="fil_client_secret">
          </mat-form-field>
        </div>

      </div>
    </div>
    <!-- / Informazioni generali -->

    <!-- Opzioni -->
    <div class="fuse-card auto-width mt-32">
      <div class="p-24">
        <span class="mat-title m-0">Opzioni</span>
      </div>

      <div class="p-24" fxLayout="row wrap" fxLayoutGap="32px">
        <mat-checkbox formControlName="fil_puoimportare">Può importare</mat-checkbox>

        <mat-checkbox formControlName="fil_gestitadasede">Gestita da sede</mat-checkbox>

        <mat-checkbox formControlName="fil_gesttariffe">Gestisce tariffe</mat-checkbox>

        <mat-checkbox formControlName="fil_vedeesitibarcode">Vede esiti barcode</mat-checkbox>

        <mat-checkbox formControlName="fil_scambio_flussi">Scambio flussi</mat-checkbox>

        <mat-checkbox formControlName="fil_vede_royalty_filiale">Vede royalty filiale</mat-checkbox>

        <mat-checkbox formControlName="fil_non_smistabile">Non smistabile</mat-checkbox>

        <mat-checkbox formControlName="fil_vedefatturazione">Vede fatturazione filiali</mat-checkbox>

      </div>
    </div>
    <!-- / Opzioni -->

    <!-- SDOC -->
    <div class="fuse-card auto-width mt-32" *ngIf="_userService.user?.sdoc">
      <div class="p-24" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="mat-title m-0">Gestione SDOC</span>

        <mat-checkbox formControlName="fil_sdoc">Attiva</mat-checkbox>
      </div>

      <fieldset class="p-24 no-border" [disabled]="!formGroup.value.fil_sdoc">
        <div fxLayout="row" fxLayoutAlign="start center">
          <app-entity-selector fxFlex="33"
                               label="Madre C"
                               searchField="fil_nome"
                               [paginator]="sdocMadreCPaginator"
                               [filters]="[{column: 'gestione', search: 'sdoc'}]"
                               [value]="sdocMadreC"
                               [entityStringifier]="filialeStringifier"
                               (valueChanges)="sdocMadreC = $event"></app-entity-selector>

          <app-entity-selector class="mx-16"
                               fxFlex="34"
                               label="Madre D"
                               searchField="fil_nome"
                               [paginator]="sdocMadreDPaginator"
                               [filters]="[{column: 'gestione', search: 'sdoc'}]"
                               [value]="sdocMadreD"
                               [entityStringifier]="filialeStringifier"
                               (valueChanges)="sdocMadreD = $event"></app-entity-selector>

          <app-entity-selector fxFlex="33"
                               label="Madre H"
                               searchField="fil_nome"
                               [paginator]="sdocMadreHPaginator"
                               [filters]="[{column: 'gestione', search: 'sdoc'}]"
                               [value]="sdocMadreH"
                               [entityStringifier]="filialeStringifier"
                               (valueChanges)="sdocMadreH = $event"></app-entity-selector>
        </div>

        <app-multi-entity-selector class="mt-16"
                                   label="Figlie C"
                                   [searchFields]="['fil_nome']"
                                   [paginator]="sdocFiglieCPaginator"
                                   [filters]="[{column: 'gestione', search: 'sdoc'}]"
                                   [dialogColumns]="filialeColumnDefinitions"
                                   [dialogDisplayedColumns]="filialeDefaultDisplayedColumns"
                                   [entityStringifier]="filialeStringifier"
                                   [(selectedEntities)]="sdocFiglieC"></app-multi-entity-selector>

        <app-multi-entity-selector class="mt-16"
                                   label="Figlie D"
                                   [searchFields]="['fil_nome']"
                                   [paginator]="sdocFiglieDPaginator"
                                   [filters]="[{column: 'gestione', search: 'sdoc'}]"
                                   [dialogColumns]="filialeColumnDefinitions"
                                   [dialogDisplayedColumns]="filialeDefaultDisplayedColumns"
                                   [entityStringifier]="filialeStringifier"
                                   [(selectedEntities)]="sdocFiglieD"></app-multi-entity-selector>

        <app-multi-entity-selector class="mt-16"
                                   label="Figlie H"
                                   [searchFields]="['fil_nome']"
                                   [paginator]="sdocFiglieHPaginator"
                                   [filters]="[{column: 'gestione', search: 'sdoc'}]"
                                   [dialogColumns]="filialeColumnDefinitions"
                                   [dialogDisplayedColumns]="filialeDefaultDisplayedColumns"
                                   [entityStringifier]="filialeStringifier"
                                   [(selectedEntities)]="sdocFiglieH"></app-multi-entity-selector>
      </fieldset>
    </div>
    <!-- / SDOC -->

    <!-- Raccomandate -->
    <div class="fuse-card auto-width mt-32" *ngIf="_userService.user?.racc">
      <div class="p-24" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="mat-title m-0">Gestione Raccomandate</span>

        <mat-checkbox formControlName="fil_racc">Attiva</mat-checkbox>
      </div>

      <fieldset class="p-24 no-border" [disabled]="!formGroup.value.fil_racc">
        <div fxLayout="row">
          <mat-form-field appearance="outline" fxFlex="50" class="mr-8">
            <mat-label>Royalty rete (€)</mat-label>
            <input matInput type="number" formControlName="fil_euroroyaltyrete">
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="50" class="ml-8">
            <mat-label>Modo fatturazione</mat-label>
            <mat-select [required]="!!filiale?.fil_racc" formControlName="fil_racc_modofatt">
              <mat-option value="T">Data inserimento</mat-option>
              <mat-option value="A">Data accettazione</mat-option>
              <mat-option value="E">Data esito</mat-option>
            </mat-select>
            <mat-error>Questo campo è obbligatorio.</mat-error>
          </mat-form-field>
        </div>

        <div class="mt-16" fxLayout="row" fxLayoutAlign="start center">
          <app-entity-selector fxFlex="33"
                               label="Madre C"
                               searchField="fil_nome"
                               [paginator]="raccMadreCPaginator"
                               [filters]="[{column: 'gestione', search: 'racc'}]"
                               [value]="raccMadreC"
                               [entityStringifier]="filialeStringifier"
                               (valueChanges)="raccMadreC = $event"></app-entity-selector>

          <app-entity-selector class="mx-16"
                               fxFlex="34"
                               label="Madre D"
                               searchField="fil_nome"
                               [paginator]="raccMadreDPaginator"
                               [filters]="[{column: 'gestione', search: 'racc'}]"
                               [value]="raccMadreD"
                               [entityStringifier]="filialeStringifier"
                               (valueChanges)="raccMadreD = $event"></app-entity-selector>

          <app-entity-selector fxFlex="33"
                               label="Madre H"
                               searchField="fil_nome"
                               [paginator]="raccMadreHPaginator"
                               [filters]="[{column: 'gestione', search: 'racc'}]"
                               [value]="raccMadreH"
                               [entityStringifier]="filialeStringifier"
                               (valueChanges)="raccMadreH = $event"></app-entity-selector>
        </div>

        <app-multi-entity-selector class="mt-16"
                                   label="Figlie C"
                                   [searchFields]="['fil_nome']"
                                   [paginator]="raccFiglieCPaginator"
                                   [filters]="[{column: 'gestione', search: 'racc'}]"
                                   [dialogColumns]="filialeColumnDefinitions"
                                   [dialogDisplayedColumns]="filialeDefaultDisplayedColumns"
                                   [entityStringifier]="filialeStringifier"
                                   [(selectedEntities)]="raccFiglieC"></app-multi-entity-selector>

        <app-multi-entity-selector class="mt-16"
                                   label="Figlie D"
                                   [searchFields]="['fil_nome']"
                                   [paginator]="raccFiglieDPaginator"
                                   [filters]="[{column: 'gestione', search: 'racc'}]"
                                   [dialogColumns]="filialeColumnDefinitions"
                                   [dialogDisplayedColumns]="filialeDefaultDisplayedColumns"
                                   [entityStringifier]="filialeStringifier"
                                   [(selectedEntities)]="raccFiglieD"></app-multi-entity-selector>

        <app-multi-entity-selector class="mt-16"
                                   label="Figlie H"
                                   [searchFields]="['fil_nome']"
                                   [paginator]="raccFiglieHPaginator"
                                   [filters]="[{column: 'gestione', search: 'racc'}]"
                                   [dialogColumns]="filialeColumnDefinitions"
                                   [dialogDisplayedColumns]="filialeDefaultDisplayedColumns"
                                   [entityStringifier]="filialeStringifier"
                                   [(selectedEntities)]="raccFiglieH"></app-multi-entity-selector>

        <div fxLayout="row wrap" fxLayoutGap="32px">
          <mat-checkbox formControlName="fil_rendericevute">Rende ricevute</mat-checkbox>

          <mat-checkbox formControlName="fil_gestesitiposte">Traccia ed esporta esiti poste</mat-checkbox>
        </div>
      </fieldset>
    </div>
    <!-- / Raccomandate -->

    <!-- Parcel -->
    <div class="fuse-card auto-width mt-32" *ngIf="_userService.user?.parcel">
      <div class="p-24" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="mat-title m-0">Gestione Parcel</span>

        <mat-checkbox formControlName="fil_parcel">Attiva</mat-checkbox>
      </div>

      <fieldset class="p-24 no-border" [disabled]="!formGroup.value.fil_parcel">
        <mat-form-field class="w-100-p" appearance="outline">
          <mat-label>Giorni calcoli dashboard</mat-label>
          <input matInput type="number" formControlName="fil_ggcalcolidashboard">
        </mat-form-field>

        <div class="mt-16" fxLayout="row" fxLayoutAlign="start center">
          <app-entity-selector fxFlex="33"
                               label="Madre C"
                               searchField="fil_nome"
                               [paginator]="parcelMadreCPaginator"
                               [filters]="[{column: 'gestione', search: 'parcel'}]"
                               [value]="parcelMadreC"
                               [entityStringifier]="filialeStringifier"
                               (valueChanges)="parcelMadreC = $event"></app-entity-selector>

          <app-entity-selector class="mx-16"
                               fxFlex="34"
                               label="Madre D"
                               searchField="fil_nome"
                               [paginator]="parcelMadreDPaginator"
                               [filters]="[{column: 'gestione', search: 'parcel'}]"
                               [value]="parcelMadreD"
                               [entityStringifier]="filialeStringifier"
                               (valueChanges)="parcelMadreD = $event"></app-entity-selector>

          <app-entity-selector fxFlex="33"
                               label="Madre H"
                               searchField="fil_nome"
                               [paginator]="parcelMadreHPaginator"
                               [filters]="[{column: 'gestione', search: 'parcel'}]"
                               [value]="parcelMadreH"
                               [entityStringifier]="filialeStringifier"
                               (valueChanges)="parcelMadreH = $event"></app-entity-selector>
        </div>

        <app-multi-entity-selector class="mt-16"
                                   label="Figlie C"
                                   [searchFields]="['fil_nome']"
                                   [paginator]="parcelFiglieCPaginator"
                                   [filters]="[{column: 'gestione', search: 'parcel'}]"
                                   [dialogColumns]="filialeColumnDefinitions"
                                   [dialogDisplayedColumns]="filialeDefaultDisplayedColumns"
                                   [entityStringifier]="filialeStringifier"
                                   [(selectedEntities)]="parcelFiglieC"></app-multi-entity-selector>

        <app-multi-entity-selector class="mt-16"
                                   label="Figlie D"
                                   [searchFields]="['fil_nome']"
                                   [paginator]="parcelFiglieDPaginator"
                                   [filters]="[{column: 'gestione', search: 'parcel'}]"
                                   [dialogColumns]="filialeColumnDefinitions"
                                   [dialogDisplayedColumns]="filialeDefaultDisplayedColumns"
                                   [entityStringifier]="filialeStringifier"
                                   [(selectedEntities)]="parcelFiglieD"></app-multi-entity-selector>

        <app-multi-entity-selector class="mt-16"
                                   label="Figlie H"
                                   [searchFields]="['fil_nome']"
                                   [paginator]="parcelFiglieHPaginator"
                                   [filters]="[{column: 'gestione', search: 'parcel'}]"
                                   [dialogColumns]="filialeColumnDefinitions"
                                   [dialogDisplayedColumns]="filialeDefaultDisplayedColumns"
                                   [entityStringifier]="filialeStringifier"
                                   [(selectedEntities)]="parcelFiglieH"></app-multi-entity-selector>

        <mat-form-field appearance="outline" class="w-100-p mt-16">
          <mat-label>Modo fatturazione filiale pagare</mat-label>
          <mat-select [required]="formGroup.value.fil_parcel" formControlName="fil_parcel_modofatt_fil">
            <mat-option value="T">Prodotto affidato in un arco temporale</mat-option>
            <mat-option value="A">Prodotto accettato in un arco temporale</mat-option>
            <mat-option value="E">Prodotto recapitato o segnalato in un determinato arco temporale</mat-option>
          </mat-select>
          <mat-error>Questo campo è obbligatorio.</mat-error>
        </mat-form-field>

        <div class="mt-16" fxLayout="row">
          <mat-checkbox class="mt-24" formControlName="fil_hub_regionale">Hub regionale</mat-checkbox>

          <fieldset class="no-border p-0 ml-16" fxFlex
                    [disabled]="!formGroup.value.fil_parcel || !formGroup.value.fil_hub_regionale"
                    >
            <mat-form-field appearance="outline" class="w-100-p">
              <mat-label>Modo fatturazione hub regionale</mat-label>
              <mat-select formControlName="fil_parcel_modofatt_hr"
                          [required]="formGroup.value.fil_parcel && formGroup.value.fil_hub_regionale">
                <mat-option value="T">Prodotto affidato in un arco temporale</mat-option>
                <mat-option value="A">Prodotto accettato in un arco temporale</mat-option>
              </mat-select>
              <mat-error>Questo campo è obbligatorio.</mat-error>
            </mat-form-field>
          </fieldset>
        </div>

        <div class="mt-16" fxLayout="row">
          <mat-checkbox class="mt-24" formControlName="fil_hub_nazionale">Hub nazionale</mat-checkbox>

          <fieldset class="no-border p-0 ml-16" fxFlex
                    [disabled]="!formGroup.value.fil_parcel || !formGroup.value.fil_hub_nazionale">
            <mat-form-field appearance="outline" class="w-100-p">
              <mat-label>Modo fatturazione hub nazionale</mat-label>
              <mat-select formControlName="fil_parcel_modofatt_hn"
                          [required]="formGroup.value.fil_parcel && formGroup.value.fil_hub_nazionale">>
                <mat-option value="T">Prodotto affidato in un arco temporale</mat-option>
                <mat-option value="A">Prodotto accettato in un arco temporale</mat-option>
              </mat-select>
              <mat-error>Questo campo è obbligatorio.</mat-error>
            </mat-form-field>
          </fieldset>
        </div>

        <div class="mt-16" fxLayout="row">

          <mat-form-field class="w-100-p" appearance="outline">
            <mat-label>Token Webservice</mat-label>
            <input matInput formControlName="fil_token_webservice" maxlength="12">
          </mat-form-field>
        </div>
      </fieldset>
    </div>
    <!-- / Parcel -->

    <!-- Atti -->
    <div class="fuse-card auto-width mt-32" *ngIf="_userService.user?.atti">
      <div class="p-24" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="mat-title m-0">Gestione Atti Giudiziari</span>

        <mat-checkbox formControlName="fil_atti">Attiva</mat-checkbox>
      </div>

      <fieldset class="p-24 no-border" [disabled]="!formGroup.value.fil_atti">
        <mat-form-field class="w-100-p" appearance="outline">
          <mat-label>Tipo</mat-label>
          <mat-select formControlName="fil_tipo">
            <mat-option value="CPG">Con punto giacenza</mat-option>
            <mat-option value="SFL">Solo filiale</mat-option>
            <mat-option value="SPG">Solo punto giacenza</mat-option>
          </mat-select>
        </mat-form-field>

        <div class="mt-16" fxLayout="row" fxLayoutAlign="start center">
          <app-entity-selector fxFlex="33"
                               label="Madre C"
                               searchField="fil_nome"
                               [paginator]="attiMadreCPaginator"
                               [filters]="[{column: 'gestione', search: 'atti'}]"
                               [value]="attiMadreC"
                               [entityStringifier]="filialeStringifier"
                               (valueChanges)="attiMadreC = $event"></app-entity-selector>

          <app-entity-selector class="mx-16"
                               fxFlex="34"
                               label="Madre D"
                               searchField="fil_nome"
                               [paginator]="attiMadreDPaginator"
                               [filters]="[{column: 'gestione', search: 'atti'}]"
                               [value]="attiMadreD"
                               [entityStringifier]="filialeStringifier"
                               (valueChanges)="attiMadreD = $event"></app-entity-selector>

          <app-entity-selector fxFlex="33"
                               label="Madre H"
                               searchField="fil_nome"
                               [paginator]="attiMadreHPaginator"
                               [filters]="[{column: 'gestione', search: 'atti'}]"
                               [value]="attiMadreH"
                               [entityStringifier]="filialeStringifier"
                               (valueChanges)="attiMadreH = $event"></app-entity-selector>
        </div>

        <app-multi-entity-selector class="mt-16"
                                   label="Figlie C"
                                   [searchFields]="['fil_nome']"
                                   [paginator]="attiFiglieCPaginator"
                                   [filters]="[{column: 'gestione', search: 'atti'}]"
                                   [dialogColumns]="filialeColumnDefinitions"
                                   [dialogDisplayedColumns]="filialeDefaultDisplayedColumns"
                                   [entityStringifier]="filialeStringifier"
                                   [(selectedEntities)]="attiFiglieC"></app-multi-entity-selector>

        <app-multi-entity-selector class="mt-16"
                                   label="Figlie D"
                                   [searchFields]="['fil_nome']"
                                   [paginator]="attiFiglieDPaginator"
                                   [filters]="[{column: 'gestione', search: 'atti'}]"
                                   [dialogColumns]="filialeColumnDefinitions"
                                   [dialogDisplayedColumns]="filialeDefaultDisplayedColumns"
                                   [entityStringifier]="filialeStringifier"
                                   [(selectedEntities)]="attiFiglieD"></app-multi-entity-selector>

        <app-multi-entity-selector class="mt-16"
                                   label="Figlie H"
                                   [searchFields]="['fil_nome']"
                                   [paginator]="attiFiglieHPaginator"
                                   [filters]="[{column: 'gestione', search: 'atti'}]"
                                   [dialogColumns]="filialeColumnDefinitions"
                                   [dialogDisplayedColumns]="filialeDefaultDisplayedColumns"
                                   [entityStringifier]="filialeStringifier"
                                   [(selectedEntities)]="attiFiglieH"></app-multi-entity-selector>
      </fieldset>
    </div>
    <!-- / Atti -->

  </form>
  <!-- / CONTENT -->

</div>
