<div class="page-layout simple right-sidebar inner-scroll">

  <!-- SIDEBAR -->
  <fuse-sidebar class="sidebar filters-sidebar" name="filters-sidebar" position="right">

    <!-- SIDEBAR CONTENT -->
    <div class="content p-24" fusePerfectScrollbar>
      <app-filters [filters]="filters" (filtersChanged)="onFiltersChanged($event)"></app-filters>
    </div>
    <!-- / SIDEBAR CONTENT -->

  </fuse-sidebar>
  <!-- / SIDEBAR -->

  <!-- CENTER -->
  <div class="center" fusePerfectScrollbar>

    <!-- HEADER -->
    <div class="header px-64 py-24" fxLayout="row" fxLayoutAlign="space-between center">
      <h2 class="m-0">PORTALETTERE</h2>

      <div fxLayout="row" fxLayoutGap="16px">
        <button mat-raised-button class="action-button" color="accent"
                [disabled]="(portaletterePaginator.isLoading | async)"
                (click)="toggleSidebar('filters-sidebar')">
          <mat-icon>filter_list</mat-icon>
          FILTRA
        </button>

        <a mat-raised-button href="#" routerLink="new" class="action-button" color="accent"
           [disabled]="(portaletterePaginator.isLoading | async)">
          <mat-icon>add</mat-icon>
          NUOVO PORTALETTERE
        </a>
      </div>
    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content" fxLayout="column">
      <mat-table class="main-table mx-64 my-24 px-24 pt-10 pb-24"
                 [dataSource]="portaletterePaginator"
                 matSort
                 matSortActive="port_id"
                 matSortDirection="asc"
                 (matSortChange)="portaletterePaginator.sort.next($event)"
                 fxFlex>
        <ng-container matColumnDef="port_id">
          <mat-header-cell mat-sort-header *matHeaderCellDef>Id</mat-header-cell>
          <mat-cell *matCellDef="let portalettere">{{portalettere.port_id}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="port_sdoc_cod">
          <mat-header-cell *matHeaderCellDef>Codice terminale SDOC</mat-header-cell>
          <mat-cell *matCellDef="let portalettere">{{portalettere.port_sdoc_cod}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="port_racc_numterm">
          <mat-header-cell *matHeaderCellDef>Numero terminale Raccomandate</mat-header-cell>
          <mat-cell *matCellDef="let portalettere">{{portalettere.port_racc_numterm}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="port_parcel_numterm">
          <mat-header-cell *matHeaderCellDef>Numero terminale Parcel</mat-header-cell>
          <mat-cell *matCellDef="let portalettere">{{portalettere.port_parcel_numterm}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="port_atti_numterm">
          <mat-header-cell *matHeaderCellDef>Numero terminale Atti</mat-header-cell>
          <mat-cell *matCellDef="let portalettere">{{portalettere.port_atti_numterm}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="port_dt_acquisto">
          <mat-header-cell mat-sort-header *matHeaderCellDef>Data acquisto</mat-header-cell>
          <mat-cell *matCellDef="let portalettere">{{portalettere.port_dt_acquisto | moment}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="port_note">
          <mat-header-cell *matHeaderCellDef>Note</mat-header-cell>
          <mat-cell *matCellDef="let portalettere">{{portalettere.port_note}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="port_cognome">
          <mat-header-cell mat-sort-header *matHeaderCellDef>Cognome</mat-header-cell>
          <mat-cell *matCellDef="let portalettere">{{portalettere.port_cognome}}</mat-cell>
        </ng-container><ng-container matColumnDef="port_nominativo">
          <mat-header-cell mat-sort-header *matHeaderCellDef>Nome</mat-header-cell>
          <mat-cell *matCellDef="let portalettere">{{portalettere.port_nominativo}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="port_dt_assunzione">
          <mat-header-cell mat-sort-header *matHeaderCellDef>Data assunzione</mat-header-cell>
          <mat-cell *matCellDef="let portalettere">{{portalettere.port_dt_assunzione | moment}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="port_tipo_contratto">
          <mat-header-cell *matHeaderCellDef>Contratto</mat-header-cell>
          <mat-cell *matCellDef="let portalettere">{{portalettere.port_tipo_contratto}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="port_indirizzo">
          <mat-header-cell *matHeaderCellDef>Indirizzo</mat-header-cell>
          <mat-cell *matCellDef="let portalettere">{{portalettere.port_indirizzo}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="port_cap">
          <mat-header-cell *matHeaderCellDef>CAP</mat-header-cell>
          <mat-cell *matCellDef="let portalettere">{{portalettere.port_cap}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="port_localita">
          <mat-header-cell *matHeaderCellDef>Località</mat-header-cell>
          <mat-cell *matCellDef="let portalettere">{{portalettere.port_localita}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="port_provincia">
          <mat-header-cell *matHeaderCellDef>Provincia</mat-header-cell>
          <mat-cell *matCellDef="let portalettere">{{portalettere.port_provincia}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="port_attivo">
          <mat-header-cell *matHeaderCellDef>Attivo</mat-header-cell>
          <mat-cell *matCellDef="let portalettere" class="text-center">
            <mat-checkbox [checked]="portalettere.port_attivo" #ckattivo (click)="$event.preventDefault();ckattivo.checked=portalettere.port_attivo"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="filiale">
          <mat-header-cell mat-sort-header *matHeaderCellDef>Filiale</mat-header-cell>
          <mat-cell *matCellDef="let portalettere">{{portalettere.filiale?.fil_nome}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions" stickyEnd>
          <mat-header-cell *matHeaderCellDef>Azioni</mat-header-cell>
          <mat-cell *matCellDef="let portalettere" >
            <a *ngIf="portalettere.port_fittizio!=1" mat-icon-button color="accent" href="#" [routerLink]="[portalettere.port_id]">
              <mat-icon>edit</mat-icon>
            </a>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
        <mat-row *matRowDef="let portalettere; columns: displayedColumns;"></mat-row>
      </mat-table>

      <div class="h-56 px-64 pb-24" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="mat-caption">@FulmineGroup</span>

        <app-paginator [paginator]="portaletterePaginator"></app-paginator>
      </div>
    </div>
    <!-- / CONTENT -->

  </div>
  <!-- / CENTER -->

</div>
