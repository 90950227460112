import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomPaginator } from 'app/fulmine/common/models/custom-paginator';

@Component({
  selector: 'app-custompaginator',
  templateUrl: './custom-paginator.component.html',
  styleUrls: ['./custom-paginator.component.scss']
})
export class CustomPaginatorComponent {
  @Input()
  public Page: number = 1;

  @Input()
  public PageSize: number = 10;

  @Input()
  public Count: number = 0;

  public get PageIndex(): number {
    var pageIndex = this.Page - 1;
    return pageIndex;
  }

  @Output()
  public PageChanged: EventEmitter<CustomPaginator> = new EventEmitter<CustomPaginator>();

  public PageChange(event: any) {
    if (event != null) {
      var pageIndex = event.pageIndex;
      var page = pageIndex + 1;
      var take = this.PageSize;
      var skip = (page - 1) * take;

      var paginator = new CustomPaginator();
      paginator.Skip = skip;
      paginator.Take = take;
      paginator.Page = page;

      if (this.PageChanged != null)
        this.PageChanged.emit(paginator);
    }
  }
}
