import {Component, Inject, OnInit} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {animate, AnimationBuilder, style} from '@angular/animations';
import {EchoService} from 'ngx-laravel-echo';
import {OAuthService} from 'angular-oauth2-oidc';
import {OperazioniUtenteService} from '../../../services/operazioni-utente.service';
import {NotificationsService} from '../../../services/notifications.service';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss']
})
export class SplashComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private _document: any,
              private _animationBuilder: AnimationBuilder,
              private _echoService: EchoService,
              private _oauthService: OAuthService,
              private _route: ActivatedRoute,
              private _router: Router,
              private _userService: UserService,
              private _operazioniService: OperazioniUtenteService,
              private _notificationsService: NotificationsService) {
  }

  async ngOnInit(): Promise<void> {
    await this._userService.load();
    this._echoService.login({Authorization: this._oauthService.authorizationHeader()}, this._userService.user.id);
    this._operazioniService.login();
    this._notificationsService.login();
    const splashScreenEl = this._document.body.querySelector('#fuse-splash-screen');
    const player =
      this._animationBuilder
        .build([
          style({opacity: '1'}),
          animate('400ms ease', style({
            opacity: '0',
            zIndex: '-10'
          }))
        ]).create(splashScreenEl);

    setTimeout(() => {
      player.play();
    }, 0);

    this._router.navigate([this._route.snapshot.queryParamMap.get('redirectTo')]);
  }
}
