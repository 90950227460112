// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  //baseUrl: 'http://kubernetes.docker.internal/',
  baseUrl: 'https://backendtest.fulminegroup.eu/',
  nodeUrl: 'https://backendtest.fulminegroup.eu/node/',//'http://34.154.27.128:4000/',//'https://backend.fulminegroup.eu/node/',
  //baseUrl: 'https://backendtest.fulminegroup.eu/',
  // echoHost: 'websocket.fulminegroup.eu',
  echoHost: 'backendfulmine.test', // con docker
  echoKey: 'DSasd434',
  // echoPort: 6001,
  // echoForceTls: false, // false con docker
  echoPort: 443,
  echoForceTls: true, // false con docker
  googleMapsKey: 'AIzaSyBceJCC-xyxpN4Rx5SPkURLRHeQD4hSFyg',
  hmr: false,
  oauth2ClientId: '2',
  oauth2ClientSecret: 'GkKEh2XCRmT1UTwFCIH8UvtVZY1OMzJx4dlxflWq',
  production: false,
  debug: true,

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
