<mat-form-field  class="w-100-p" appearance="outline">
  <mat-label>Filiali</mat-label>




    <mat-chip-list #chipList>
      <mat-chip fxFlex *ngFor="let entity of selectedEntities; let i = index"
                [removable]="true"
                (removed)="onEntityRemoved(i)">
        {{filialeStringifier(entity)}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>

    <div matSuffix fxLayout="row">
      <button fxFlex mat-icon-button  (click)="onAddButtonClicked()"><mat-icon>add</mat-icon></button>
      <button fxFlex *ngIf="selectedEntities.length>0" mat-icon-button (click)="onReset()"><mat-icon>clear</mat-icon></button>
    </div>
  <input matInput readonly [matChipInputFor]="chipList" [required]="required">
</mat-form-field>
