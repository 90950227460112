<h1 mat-dialog-title>Aggiungi la Distina</h1>
<div mat-dialog-content>
  <app-file-dropper
    label="Inserisci qui la Distinta"
    [maxSize]="this.maxSize"
    class="w-100-p"
    (fileSelected)="files = $event"
    [filters]="['.pdf', '.p7m']"
    [multiple]="false"
  ></app-file-dropper>
</div>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
  <div>
    <mat-progress-spinner *ngIf="isLoading | async" color="accent" diameter="24" mode="indeterminate"></mat-progress-spinner>
  </div>

  <div>
    <button mat-flat-button mat-dialog-close>Annulla</button>
    <button mat-raised-button color="accent" [disabled]="files == null" (click)="onConfirmButtonClicked()">Conferma</button>
  </div>
</mat-dialog-actions>
