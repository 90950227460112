import { Injectable } from '@angular/core';
import {Paginator} from '../../models/paginator';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {Regione} from '../../models/entities/regione';

@Injectable({
  providedIn: 'root'
})
export class RegioniPaginatorService extends Paginator<Regione>{

  constructor(http: HttpClient) {
    super(http, `${environment.baseUrl}api/regioni`);
  }
}

