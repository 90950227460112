import {Injectable} from '@angular/core';
import {ApiService} from '../../models/api-service';
import {Cliente} from '../../models/entities/cliente';
import {environment} from '../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Types} from "../../models/entities/system";
import {Appalto} from "../../../sdoc/models/entities/appalto";
import {ApiResponse} from "../../models/api-response";

@Injectable({
  providedIn: 'root'
})
export class ClientiApiService extends ApiService<Cliente> {

  constructor(http: HttpClient) {
    super(http, `${environment.baseUrl}api/clienti`);
  }

  public async getClienteFormTipo(id: number | string, tipo: Types): Promise<Cliente> {
    this.isLoading.next(true);
    let result = null;
    try {
      result = await this.http.get<Cliente>(`${this.url}/${id}/${tipo}`).toPromise();
    } catch (e) {
    }
    this.isLoading.next(false);
    return result;
  }

}
