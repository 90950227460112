import {User} from './user';

export interface Operazione {
  id: number;
  user_id: number;
  user: User;
  gestione: string;
  tipo: string;
  titolo: string;
  stato: StatoOperazione;
  data: {};
  dtora_inizio: string;
  dtora_fine: string;
  dettagli: OperazioneDettaglio[];
}

export interface OperazioneDettaglio {
  id: number;
  sottotipo: string;
  stato: StatoOperazione;
  messaggio: string;
  data: {};
  dtora_inizio: string;
  dtora_fine: string;
}

export enum StatoOperazione {
  IN_CORSO = 'in corso',
  ERRORE = 'errore',
  COMPLETATO = 'completato',
  ATTENZIONATO = 'attenzionato'
}
