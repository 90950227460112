<div class="page-layout simple right-sidebar inner-scroll">
  <!-- SIDEBAR -->
  <fuse-sidebar
    class="sidebar filters-sidebar"
    name="filters-sidebar"
    position="right"
  >
    <!-- SIDEBAR CONTENT -->
    <div class="content p-24" fusePerfectScrollbar>
      <app-filters
        [filters]="filters"
        (filtersChanged)="onFiltersChanged($event)"
      ></app-filters>
    </div>
    <!-- / SIDEBAR CONTENT -->
  </fuse-sidebar>
  <!-- / SIDEBAR -->

  <!-- CENTER -->
  <div class="center" fusePerfectScrollbar>
    <!-- HEADER -->
    <div
      class="header px-64 py-24"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <h2 class="m-0">MANUTENZIONE</h2>

      <div fxLayout="row" fxLayoutGap="16px">
        <button
          mat-raised-button
          class="action-button"
          color="accent"
          (click)="toggleSidebar('filters-sidebar')"
        >
          <mat-icon>filter_list</mat-icon> FILTRA
        </button>

        <a
          mat-raised-button
          class="action-button"
          color="accent"
          href="#"
          routerLink="new"
        >
          <mat-icon>add</mat-icon> NUOVA TARIFFA
        </a>
      </div>
    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content" fxLayout="column">
      <mat-table
        class="main-table mx-64 my-24 px-24 pt-10 pb-24"
        [dataSource]="manutenzionePaginator"
        matSort
        matSortActive="id"
        matSortDirection="asc"
        (matSortChange)="manutenzionePaginator.sort.next($event)"
        fxFlex
      >
        <!-- <ng-container matColumnDef="id">
          <mat-header-cell mat-sort-header *matHeaderCellDef>Id</mat-header-cell>
          <mat-cell *matCellDef="let tariffa">{{tariffa.id}}</mat-cell>
        </ng-container> -->
        <ng-container matColumnDef="db"
        >
          <mat-header-cell mat-sort-header *matHeaderCellDef
            >db</mat-header-cell
          >
          <mat-cell *matCellDef="let tariffa" >{{ tariffa.db }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="table_name">
          <mat-header-cell mat-sort-header *matHeaderCellDef
            >Tabella</mat-header-cell
          >
          <mat-cell *matCellDef="let tariffa">{{
            tariffa.table_name
          }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="prod_num_rows">
          <mat-header-cell mat-sort-header *matHeaderCellDef
            >Numero Righe Prod</mat-header-cell
          >
          <mat-cell *matCellDef="let tariffa">{{
            tariffa.prod_num_rows
          }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="table_rows">
          <mat-header-cell mat-sort-header *matHeaderCellDef
            >Numero Righe Test</mat-header-cell
          >
          <mat-cell *matCellDef="let tariffa">{{
            tariffa.table_rows
          }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
          <mat-header-cell mat-sort-header *matHeaderCellDef
            >status</mat-header-cell
          >
          <mat-cell *matCellDef="let tariffa">
            <span
              class="status-dot"
              [class.green-dot]="tariffa.status === 1"
              [class.red-dot]="tariffa.status === 0"
            ></span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="last_updated">
          <mat-header-cell *matHeaderCellDef>last_updated</mat-header-cell>
          <mat-cell *matCellDef="let tariffa" class="text-center">
            {{ tariffa.last_updated | date : "dd/MM/yyyy HH:mm" }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="query">
          <mat-header-cell *matHeaderCellDef>query</mat-header-cell>
          <mat-cell *matCellDef="let tariffa">
            <mat-form-field>
              <input
                matInput
                #whereRef
                (change)="getWhere({ input: $event, id: tariffa.id })"
                [value]="tariffa.where"
              />
            </mat-form-field>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions" stickyEnd>
          <mat-header-cell *matHeaderCellDef>actions</mat-header-cell>
          <mat-cell *matCellDef="let tariffa">
            <button
              mat-raised-button
              class="action-button"
              color="accent"
              (click)="startUpdating(tariffa.id)"
              [disabled]="tariffa.enableUpdate"
            >
              <mat-icon>update</mat-icon> Aggiorna
            </button>
            <button
              mat-raised-button
              class="action-button"
              color="accent"
              (click)="startTruncate(tariffa.id)"
              [disabled]="tariffa.enableUpdate"
            >
              <mat-icon>stop</mat-icon> Truncate
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></mat-header-row>
        <mat-row *matRowDef="let tariffa; columns: displayedColumns"></mat-row>
      </mat-table>

      <div
        class="h-56 px-64 pb-24"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <span class="mat-caption">@FulmineGroup</span>

        <app-paginator [paginator]="manutenzionePaginator"></app-paginator>
      </div>
    </div>
    <!-- / CONTENT -->
  </div>
  <!-- / CENTER -->
</div>
