import {Filiale} from './filiale';
import {Cliente} from './cliente';
import {TipoUtente} from './tipo-utente';

export interface User {
  id: number;
  attivo: number;
  email: string;
  name: string;
  username: string;
  sdoc: number;
  racc: number;
  atti: number;
  parcel: number;
  sdoc_id: number;
  racc_id: number;
  atti_id: number;
  parcel_id: number;
  sdoc_tipo: number;
  racc_tipo: number;
  atti_tipo: number;
  parcel_tipo: number;
  cliente: Cliente;
  clienti_venditore: Cliente[];
  filiale: Filiale;
  tiposdoc: TipoUtente;
  tiporacc: TipoUtente;
  tipoatti: TipoUtente;
  tipoparcel: TipoUtente;
  permissions: string[];
  roles: string[];
  creatoreid: number;
}

export function userIdentifier(user: User): number {
  return user.id;
}

export function userIdentifierRacc(user: User): number {
  return user.racc_id;
}

export function userStringifier(user: User): string {
  return user ? user.name : '';
}
