import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {OperazioneDettaglio} from '../../../models/entities/operazione';

@Component({
  selector: 'app-operazione-dettaglio-data-dialog',
  templateUrl: './operazione-dettaglio-data-dialog.component.html',
  styleUrls: ['./operazione-dettaglio-data-dialog.component.scss']
})
export class OperazioneDettaglioDataDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: OperazioneDettaglio) {
  }
}
