import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {EchoService} from 'ngx-laravel-echo';
import {OAuthService} from 'angular-oauth2-oidc';
import {Router} from '@angular/router';
import {UserService} from './user.service';
import {OperazioniUtentePaginatorService} from './paginators/operazioni-utente-paginator.service';
import {Operazione, StatoOperazione} from '../models/entities/operazione';

@Injectable({
  providedIn: 'root'
})
export class OperazioniUtenteService {
  public operazioni: Operazione[] = [];
  public workingOperations = 0;

  public get isLoading(): BehaviorSubject<boolean> {
    return this._operazioniPaginator.isLoading;
  }

  constructor(private _echoService: EchoService,
              private _operazioniPaginator: OperazioniUtentePaginatorService,
              private _oauthService: OAuthService,
              private _router: Router,
              private _userService: UserService) {
  }

  public async loadNext(): Promise<void> {
    const operazioni = (await this._operazioniPaginator.loadNext())?.data ?? [];
    for (const operazione of operazioni) {
      if (!this.operazioni.some(n => n.id === operazione.id)) {
        this.operazioni.push(operazione);
      }
    }
    this.calculateWorkingOperazioni();
  }

  private calculateWorkingOperazioni(): void {
    this.workingOperations = this.operazioni.filter(n => n.stato === StatoOperazione.IN_CORSO).length;
  }

  public login(): void {
    this.initOperations();
    this.loadNext();
  }

  public logout(): void {
    this.operazioni = [];
  }

  private initOperations(): void {
    this._echoService.listen(`user.${this._userService.user.id}`, '.operazioni').subscribe(event => {
      const index = this.operazioni.findIndex(operazione => operazione.id === event.operazione.id);
      if (index >= 0) {
        this.operazioni.splice(index, 1, event.operazione);
      } else {
        this.operazioni.splice(0, 0, event.operazione);
      }
      this.calculateWorkingOperazioni();
    });
    this._operazioniPaginator.sort.next({active: 'id', direction: 'desc'});
  }
}
