import {TableColumn, TableColumnType} from '../table-column';

export interface Cliente {
  cli_id: number;
  cli_appcod_sdoc: string;
  cli_nome_rag_soc: string;
  cli_nome: string;
  cli_ente_sdoc: string;
  cli_indirizzo: string;
  cli_cap: string;
  cli_localita: string;
  cli_prov: string;
  cli_cig: string;
  cli_piva: string;
  cli_cf: string;
  cli_note: string;
  cli_email: string;
  cli_padreid: number;
  cli_filroyid: number;
  cli_reportcliente: number;
  cli_codcliarca: string;
  cli_spedirenuovi: number;
  cli_attivo: number;
  cli_faparcel: number;
  cli_spediretutto: number;
  cli_ricercanota2: number;
  cli_spedisce_dt_compiuta_giac: number;
  cli_spedisce_dt_in_consegna: number;
  cli_inverti_cli_sottocli_su_ric: number;
  cli_esitiposte: number;
  cli_parcel_obbligodispacci: number;
  cli_parcel_ggslarecapito: number;
  cli_parcel_riceveesitiftp: number;
  cli_parcel_frequenzainvioesitiftp: number;
  cli_parcel_giornispedizioneftp: number;
  cli_parcel_ggalertpaccononpervenuto: number;
  cli_parcel_modo_fatturazione: string;
  cli_racc_non_considerare_padre_su_ricevute: number;
  cli_racc_modo_fatturazione: string;
  cli_racc_firmaelettronica: boolean;
  cli_racc_riceveesitiftp: boolean;
  cli_racc_frequenzainvioesitiftp: number;
  cli_racc_giornispedizioneftp: number;
  cli_racc_spedisciricevute: boolean;
  cli_racc_spediscicontracciatoeglue: boolean;
  cli_racc_spediscicontracciatoenel: boolean;
  cli_racc_menu_lotti: boolean;
  cli_sdoc_vuota_dest_dopo_gg: number;
  cli_sdoc_sla_am: number;
  cli_sdoc_sla_cp: number;
  cli_sdoc_sla_eu: number;
  cli_sdoc_gg_chiudi_am: number;
  cli_sdoc_gg_chiudi_cp: number;
  cli_sdoc_gg_chiudi_eu: number;
  cli_sdoc_sla_r: number;
  cli_sdoc_chiudi_smistamento: number;
  cli_sdoc_vede_linea: number;
  cli_sdoc_controllo: number;
  cli_sdoc_gg_controllo: number;
  cli_sdoc_spedizione: number;
  cli_sdoc_sportello: number;
  cli_sdoc_sportello_cod: number;
  cli_sdoc_tracciato_resi_dettagliato: number;
  cli_sdoc: number;
  cli_atti: string;
  cli_parcel: number;
  cli_racc: number;
  cli_sdocid: number;
  cli_attiid: string;
  cli_parcelid: number;
  cli_raccid: number;
  cli_nome_controllo: string;
  cli_cod_agente: string;
  cli_menu_utenti: number;
  padre: Cliente;
  figli: Cliente[];
  cli_amministratore: boolean;
}

export function clienteIdentifier(cliente: Cliente): number {
  return cliente.cli_id;
}

export function clienteIdentifierSdoc(cliente: Cliente): number {
  return cliente.cli_sdocid;
}

export function clienteIdentifierRacc(cliente: Cliente): number {
  return cliente.cli_raccid;
}

export function clienteStringifier(cliente: Cliente): string {
  return cliente.cli_nome_rag_soc;
}

export const clienteColumnDefinitions: TableColumn[] = [
  {column: 'cli_id', label: 'Id', type: TableColumnType.TEXT},
  {column: 'cli_nome_rag_soc', label: 'Nome', type: TableColumnType.TEXT},
];

export const clienteDefaultDisplayedColumns: string[] = [
  'cli_id', 'cli_nome_rag_soc',
];
