<div class="page-layout simple right-sidebar inner-scroll">

  <!-- SIDEBAR -->
  <fuse-sidebar class="sidebar filters-sidebar" name="filters-sidebar" position="right">

    <!-- SIDEBAR CONTENT -->
    <div class="content p-24" fusePerfectScrollbar>
      <app-filters [filters]="filters" (filtersChanged)="onFiltersChanged($event)"></app-filters>
    </div>
    <!-- / SIDEBAR CONTENT -->

  </fuse-sidebar>
  <!-- / SIDEBAR -->

  <!-- CENTER -->
  <div class="center" fusePerfectScrollbar>

    <!-- HEADER -->
    <div class="header px-64 py-24" fxLayout="row" fxLayoutAlign="space-between center">
      <h2 class="m-0">OPERAZIONI</h2>

      <div fxLayout="row" fxLayoutGap="16px">
        <button mat-raised-button class="action-button" color="accent"
                [disabled]="(operazioniPaginator.isLoading | async)"
                (click)="toggleSidebar('filters-sidebar')">
          <mat-icon>filter_list</mat-icon>
          FILTRA
        </button>
      </div>
    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content" fxLayout="column">
      <mat-table class="main-table mx-64 my-24 px-24 pt-10 pb-24"
                 [dataSource]="operazioniPaginator"
                 matSort
                 matSortActive="id"
                 matSortDirection="desc"
                 (matSortChange)="operazioniPaginator.sort.next($event)"
                 fxFlex>
        <ng-container matColumnDef="id">
          <mat-header-cell mat-sort-header *matHeaderCellDef>Id</mat-header-cell>
          <mat-cell *matCellDef="let operazione">{{operazione.id}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="gestione">
          <mat-header-cell mat-sort-header *matHeaderCellDef>Gestione</mat-header-cell>
          <mat-cell *matCellDef="let operazione">{{operazione.gestione}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="utente">
          <mat-header-cell mat-sort-header *matHeaderCellDef>Utente</mat-header-cell>
          <mat-cell *matCellDef="let operazione">{{operazione.user?.name}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="tipo">
          <mat-header-cell *matHeaderCellDef>Tipo</mat-header-cell>
          <mat-cell *matCellDef="let operazione">{{operazione.tipo}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="titolo">
          <mat-header-cell *matHeaderCellDef>titolo</mat-header-cell>
          <mat-cell *matCellDef="let operazione">{{operazione.titolo}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="stato">
          <mat-header-cell *matHeaderCellDef>stato</mat-header-cell>
          <mat-cell *matCellDef="let operazione">{{operazione.stato}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="dtora_inizio">
          <mat-header-cell mat-sort-header *matHeaderCellDef>DataOra inizio</mat-header-cell>
          <mat-cell *matCellDef="let operazione">{{operazione.dtora_inizio | moment}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="dtora_fine">
          <mat-header-cell mat-sort-header *matHeaderCellDef>DataOra fine</mat-header-cell>
          <mat-cell *matCellDef="let operazione">{{operazione.dtora_fine | moment}}</mat-cell>
        </ng-container>


        <ng-container matColumnDef="actions" stickyEnd>
          <mat-header-cell *matHeaderCellDef>Azioni</mat-header-cell>
          <mat-cell *matCellDef="let operazione" >
            <a mat-icon-button color="accent" href="#" [routerLink]="[ operazione.id]">
              <mat-icon>visibility</mat-icon>
            </a>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
        <mat-row *matRowDef="let operazione; columns: displayedColumns;"></mat-row>
      </mat-table>

      <div class="h-56 px-64 pb-24" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="mat-caption">@FulmineGroup</span>

        <app-paginator [paginator]="operazioniPaginator"></app-paginator>
      </div>
    </div>
    <!-- / CONTENT -->

  </div>
  <!-- / CENTER -->

</div>
