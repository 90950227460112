import {Component, OnInit} from '@angular/core';
import {FuseConfigService} from '../../../../../../@fuse/services/config.service';
import {fuseAnimations} from '../../../../../../@fuse/animations';
import {UserService} from '../../../services/user.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-systems',
  templateUrl: './systems.component.html',
  styleUrls: ['./systems.component.scss'],
  animations: fuseAnimations
})
export class SystemsComponent implements OnInit {

  constructor(private _fuseConfigService: FuseConfigService,
              private _router: Router,
              public userService: UserService) {
  }

  ngOnInit(): void {
    // Configure the layout

    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };
  }

  public goToSdocDashboard(): void{
    // const currentUrl = this._router.url;
    // this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    // this._router.onSameUrlNavigation = 'reload';
    this._router.navigate(['/sdoc/dashboard']).then(() => {
      window.location.reload();
    });
  }

  public goToRaccDashboard(): void{
    // const currentUrl = this._router.url;
    // this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    // this._router.onSameUrlNavigation = 'reload';
    this._router.navigate(['/raccomandate/dashboard']).then(() => {
      window.location.reload();
    });
  }
}



