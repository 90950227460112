<h1 mat-dialog-title>{{title}}</h1>
<mat-dialog-content>
  <mat-form-field appearance="outline">
    <input matInput [formControl]="formControl" [matDatepicker]="datePicker">
    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
    <mat-datepicker #datePicker></mat-datepicker>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions  fxLayout="row" fxLayoutAlign="end" fxLayoutGap="16px">
  <button mat-flat-button mat-dialog-close>Annulla</button>
  <button mat-raised-button (click)="onConfirmButtonClicked()" color="accent" [disabled]="!formControl.value">Conferma</button>
</mat-dialog-actions>
