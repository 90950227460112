import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-dropdown-dialog',
  templateUrl: './dropdown-dialog.component.html',
  styleUrls: ['./dropdown-dialog.component.scss']
})
export class DropdownDialogComponent implements OnInit {
  title: string;
  required = false;
  label: string;
  options: Option[];
  formControl = new FormControl('');

  constructor(
    private _dialogRef: MatDialogRef<DropdownDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Payload) {

    if (data) {
      this.title = data.title;
      this.required = data.required;
      this.label = data.label;
      this.options = data.options;
      if (this.required) {
        this.formControl = new FormControl('', Validators.required);
      }
    }
  }

  public onConfirmButtonClicked(): void {
    if (this.formControl.valid) {
      this._dialogRef.close(this.formControl.value);
    } else {
      this.formControl.markAllAsTouched();
    }
  }

  ngOnInit(): void {
  }

}

export interface Payload {
  title: string;
  label: string;
  options: Option[];
  required: boolean;
}

interface Option {
  label: string;
  value: string;
}
