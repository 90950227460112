import { Component, OnInit } from '@angular/core';
import { User } from '../../../models/entities/user';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersApiService } from '../../../services/api/users-api.service';
import { Cliente, clienteStringifier } from '../../../models/entities/cliente';
import { ClientiPaginatorService } from '../../../services/paginators/clienti-paginator.service';
import { Filiale, filialeStringifier } from '../../../models/entities/filiale';
import { FilialiPaginatorService } from '../../../services/paginators/filiali-paginator.service';
import { TipoUtente, tipoUtenteStringifier } from '../../../models/entities/tipo-utente';
import { TipiUtentePaginatorService } from '../../../services/paginators/tipi-utente-paginator.service';
import { Query } from '../../../models/paginator';
import { ConditionalValidator } from '../../../models/conditional-validator';
import { ApiResponse } from '../../../models/api-response';
import { ConfirmValidator } from '../../../models/confirm-validator';
import { UserService } from '../../../services/user.service';
import { SimpleAlertDialogComponent } from '../../dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent extends BaseComponent implements OnInit {

  public user: User;
  public cliente: Cliente;
  public clientePaginator: ClientiPaginatorService;
  public filiale: Filiale;
  public filialePaginator: FilialiPaginatorService;
  public clientiVenditore: Cliente[] = [];
  public clientiVenditorePaginator: ClientiPaginatorService;
  public tipoAtti: TipoUtente;
  public tipoAttiPaginator: TipiUtentePaginatorService;
  public tipoParcel: TipoUtente;
  public tipoParcelPaginator: TipiUtentePaginatorService;
  public tipoRacc: TipoUtente;
  public tipoRaccPaginator: TipiUtentePaginatorService;
  public tipoSdoc: TipoUtente;
  public tipoSdocPaginator: TipiUtentePaginatorService;
  public formGroup: FormGroup;
  public showTipoRacc = true;

  public clienteStringifier = clienteStringifier;
  public filialeStringifier = filialeStringifier;
  public tipoUtenteStringifier = tipoUtenteStringifier;

  public hidePassword = true;
  public hideConfirmPassword = true;

  get isCliente(): boolean {
    return this.tipoAtti?.cliente === 1
      || this.tipoParcel?.cliente === 1
      || this.tipoRacc?.cliente === 1
      || this.tipoSdoc?.cliente === 1;
  }

  get isFiliale(): boolean {
    return this.tipoAtti?.filiale === 1
      || this.tipoParcel?.filiale === 1
      || this.tipoRacc?.filiale === 1
      || this.tipoSdoc?.filiale === 1;
  }

  get tipiFilter(): Query[] {
    const queries: Query[] = [];
    if (this.tipoAtti) {
      queries.push({ column: 'comp_atti', search: this.tipoAtti.id });
    }
    if (this.tipoParcel) {
      queries.push({ column: 'comp_parcel', search: this.tipoParcel.id });
    }
    if (this.tipoRacc) {
      queries.push({ column: 'comp_racc', search: this.tipoRacc.id });
    }
    if (this.tipoSdoc) {
      queries.push({ column: 'comp_sdoc', search: this.tipoSdoc.id });
    }
    return queries;
  }

  get gestioniFilter(): Query {
    const search: string[] = [];
    if (this.formGroup.value.atti) {
      search.push('atti');
    }
    if (this.formGroup.value.parcel) {
      search.push('parcel');
    }
    if (this.formGroup.value.racc) {
      search.push('racc');
    }
    if (this.formGroup.value.sdoc) {
      search.push('sdoc');
    }

    return { column: 'gestione', search: search.join(',') };
  }

  constructor(public _usersApiService: UsersApiService,
    private _formBuilder: FormBuilder,
    private _location: Location,
    private _route: ActivatedRoute,
    public _userService: UserService,
    private router: Router,
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.clientePaginator = new ClientiPaginatorService(this._http);
    this.filialePaginator = new FilialiPaginatorService(this._http);
    this.clientiVenditorePaginator = new ClientiPaginatorService(this._http);
    this.tipoAttiPaginator = new TipiUtentePaginatorService(this._http);
    this.tipoParcelPaginator = new TipiUtentePaginatorService(this._http);
    this.tipoRaccPaginator = new TipiUtentePaginatorService(this._http);
    this.tipoSdocPaginator = new TipiUtentePaginatorService(this._http);

    if (this._route.snapshot.paramMap.has('id')) {
      this._fuseProgressBarService.show();
      this.user = await this._usersApiService.get(parseInt(this._route.snapshot.paramMap.get('id'), 10));
      this.cliente = this.user.cliente;
      this.clientiVenditore = this.user.clienti_venditore;
      this.filiale = this.user.filiale;
      this.tipoAtti = this.user.tipoatti;
      this.tipoRacc = this.user.tiporacc;
      this.tipoSdoc = this.user.tiposdoc;
      this.tipoParcel = this.user.tipoparcel;
      this._fuseProgressBarService.hide();
    }

    this.formGroup = this._formBuilder.group({
      attivo: [
        this.user?.attivo ?? true,
        Validators.required
      ],
      username: [
        this.user?.username ?? '',
        [Validators.required, Validators.maxLength(191)]
      ],
      name: [
        this.user?.name ?? '',
        [Validators.required, Validators.maxLength(191)]
      ],
      email: [
        this.user?.email ?? '',
        [Validators.email, Validators.required, Validators.maxLength(191)]
      ],
      password: [
        '',
        Validators.compose([
          Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@()$%^&*=_{}[\\]:;\\"\'|\\\\<>,.\\/~`±§+-]).{8,30}$'),
          ConditionalValidator.predicate(() => !this.user, Validators.required),
        ])
      ],
      password_confirmation: [
        '',
        ConditionalValidator.predicate(() => this.formGroup.value.password !== '', Validators.required),
      ],

      // Systems
      atti: [
        this.user?.atti ?? false,
        Validators.required
      ],
      parcel: [
        this.user?.parcel ?? false,
        Validators.required
      ],
      racc: [
        this.user?.racc ?? false,
        Validators.required
      ],
      sdoc: [
        this.user?.sdoc ?? false,
        Validators.required
      ],

      // SDOC
      sdoc_menu_reportresi: [
        this.user?.permissions?.includes('sdoc_menu_reportresi') ?? false,
        ConditionalValidator.predicate(() => this.formGroup.value.sdoc, Validators.required)
      ],
      sdoc_ricparzbarc: [
        this.user?.permissions?.includes('sdoc_ricparzbarc') ?? false,
        ConditionalValidator.predicate(() => this.formGroup.value.sdoc, Validators.required)
      ],
      sdoc_clisoloricerche: [
        this.user?.permissions?.includes('sdoc_clisoloricerche') ?? false,
        ConditionalValidator.predicate(() => this.formGroup.value.sdoc, Validators.required)
      ],
      sdoc_ricfattcodut: [
        this.user?.permissions?.includes('sdoc_ricfattcodut') ?? false,
        ConditionalValidator.predicate(() => this.formGroup.value.sdoc, Validators.required)
      ],
      sdoc_riclocalita: [
        this.user?.permissions?.includes('sdoc_riclocalita') ?? false,
        ConditionalValidator.predicate(() => this.formGroup.value.sdoc, Validators.required)
      ],
      sdoc_menu_ricerche: [
        this.user?.permissions?.includes('sdoc_menu_ricerche') ?? false,
        ConditionalValidator.predicate(() => this.formGroup.value.sdoc, Validators.required)
      ],
      sdoc_menu_lotti: [
        this.user?.permissions?.includes('sdoc_menu_lotti') ?? false,
        ConditionalValidator.predicate(() => this.formGroup.value.sdoc, Validators.required)
      ],
      sdoc_menu_affidiclienti: [
        this.user?.permissions?.includes('sdoc_menu_affidiclienti') ?? false,
        ConditionalValidator.predicate(() => this.formGroup.value.sdoc, Validators.required)
      ],
      sdoc_menu_reportclienti: [
        this.user?.permissions?.includes('sdoc_menu_reportclienti') ?? false,
        ConditionalValidator.predicate(() => this.formGroup.value.sdoc, Validators.required)
      ],
      sdoc_cerca_stato_segn: [
        this.user?.permissions?.includes('sdoc_cerca_stato_segn') ?? false,
        ConditionalValidator.predicate(() => this.formGroup.value.sdoc, Validators.required)
      ],
      sdoc_conteggidettlotto: [
        this.user?.permissions?.includes('sdoc_conteggidettlotto') ?? false,
        ConditionalValidator.predicate(() => this.formGroup.value.sdoc, Validators.required)
      ],
      sdoc_menu_utenti: [
        this.user?.permissions?.includes('sdoc_menu_utenti') ?? false,
        ConditionalValidator.predicate(() => this.formGroup.value.sdoc, Validators.required)
      ],
      sdoc_vede_inlinea: [
        this.user?.permissions?.includes('sdoc_vede_inlinea') ?? false,
        ConditionalValidator.predicate(() => this.formGroup.value.sdoc, Validators.required)
      ],
      sdoc_esportaresituttilotti: [
        this.user?.permissions?.includes('sdoc_esportaresituttilotti') ?? false,
        ConditionalValidator.predicate(() => this.formGroup.value.sdoc, Validators.required)
      ],
      sdoc_usa_tariffe: [
        this.user?.permissions?.includes('sdoc_usa_tariffe') ?? false,
        ConditionalValidator.predicate(() => this.formGroup.value.sdoc, Validators.required)
      ],
      sdoc_importa: [
        this.user?.permissions?.includes('sdoc_importa') ?? false,
        ConditionalValidator.predicate(() => this.formGroup.value.sdoc, Validators.required)
      ],
      sdoc_menu_coperturacap: [
        this.user?.permissions?.includes('sdoc_menu_coperturacap') ?? false,
        ConditionalValidator.predicate(() => this.formGroup.value.sdoc, Validators.required)
      ],
      sdoc_vede_fattura_su_resi: [
        this.user?.permissions?.includes('sdoc_vede_fattura_su_resi') ?? false,
        ConditionalValidator.predicate(() => this.formGroup.value.sdoc, Validators.required)
      ],
      sdoc_vede_codutente_su_resi: [
        this.user?.permissions?.includes('sdoc_vede_codutente_su_resi') ?? false,
        ConditionalValidator.predicate(() => this.formGroup.value.sdoc, Validators.required)
      ],
      sdoc_richiesta_ristampa: [
        this.user?.permissions?.includes('sdoc_richiesta_ristampa') ?? false,
        ConditionalValidator.predicate(() => this.formGroup.value.sdoc, Validators.required)
      ],
      sdoc_menu_report_lotti_aqp: [
        this.user?.permissions?.includes('sdoc_menu_report_lotti_aqp') ?? false,
        ConditionalValidator.predicate(() => this.formGroup.value.sdoc, Validators.required)
      ],

      user_racc_menu_ricerche: [
        this.user?.permissions?.includes('racc_menu_ricerche') && this.user.tiporacc?.descrizione === 'Cliente Operatore',
        ConditionalValidator.predicate(() => this.formGroup.value.racc && this.formGroup.value.racc_tipo?.descrizione === 'Cliente Operatore', Validators.required)
      ],
      user_racc_menu_ristampabarcode: [
        this.user?.permissions?.includes('racc_menu_ristampabarcode') && this.user.tiporacc?.descrizione === 'Cliente Operatore',
        ConditionalValidator.predicate(() => this.formGroup.value.racc && this.formGroup.value.racc_tipo?.descrizione === 'Cliente Opera tore', Validators.required)
      ],
      user_racc_menu_lotti: [
        this.user?.permissions?.includes('racc_menu_lotti') && this.user.tiporacc?.descrizione === 'Cliente Operatore',
        ConditionalValidator.predicate(() => this.formGroup.value.racc && this.formGroup.value.racc_tipo?.descrizione === 'Cliente Operatore', Validators.required)
      ],
      user_racc_menu_reportclienti: [
        this.user?.permissions?.includes('racc_menu_reportclienti') && this.user.tiporacc?.descrizione === 'Cliente Operatore',
        ConditionalValidator.predicate(() => this.formGroup.value.racc && this.formGroup.value.racc_tipo?.descrizione === 'Cliente Operatore', Validators.required)
      ],
      user_racc_vede_filtro_stato_su_ricerche: [
        this.user?.permissions?.includes('racc_vede_filtro_stato_su_ricerche') && this.user.tiporacc?.descrizione === 'Cliente Operatore',
        ConditionalValidator.predicate(() => this.formGroup.value.racc && this.formGroup.value.racc_tipo?.descrizione === 'Cliente Operatore', Validators.required)
      ],
      user_racc_vede_segn_assente_su_filtro_segnalazioni_ricerche: [
        this.user?.permissions?.includes('racc_vede_segn_assente_su_filtro_segnalazioni_ricerche') && this.user.tiporacc?.descrizione === 'Cliente Operatore',
        ConditionalValidator.predicate(() => this.formGroup.value.racc && this.formGroup.value.racc_tipo?.descrizione === 'Cliente Operatore', Validators.required)
      ],
      user_racc_richiesta_ristampa: [
        this.user?.permissions?.includes('racc_richiesta_ristampa') ?? false,
        ConditionalValidator.predicate(() => this.formGroup.value.racc, Validators.required)
      ],

    }, { validators: [ConfirmValidator.passwordValidation] });
  }

  public async onSaveButtonClicked(): Promise<void> {
    if (this.formGroup.invalid) {
      return;
    }

    const permissions = [];
    for (const key of Object.keys(this.formGroup.value)) {
      if (key.startsWith('sdoc_') && this.formGroup.value[key]) {
        permissions.push(key);
      }
    }

    const clienti = [];
    for (const cliente of this.clientiVenditore) {
      clienti.push(cliente.cli_id);
    }

    // TODO: dipendete 'dip_id' => 'required_if:atti_tipo,7|exists:central.dipendenti_interni,id',

    const body: any = {
      attivo: this.formGroup.value.attivo,
      username: this.formGroup.value.username,
      name: this.formGroup.value.name,
      email: this.formGroup.value.email,
      password: this.formGroup.value.password,
      password_confirmation: this.formGroup.value.password_confirmation,
      atti: this.formGroup.value.atti,
      parcel: this.formGroup.value.parcel,
      racc: this.formGroup.value.racc,
      sdoc: this.formGroup.value.sdoc,
      permissions,
      clienti,
    };

    if (this.filiale) {
      body.fil_id = this.filiale.fil_id;
    }

    if (this.cliente) {
      body.cli_id = this.cliente.cli_id;
    }

    if (this.formGroup.value.sdoc) {
      body.sdoc_tipo = this.tipoSdoc.id;
    }

    if (this.formGroup.value.racc) {
      body.racc_tipo = this.tipoRacc.id;
      body.user_racc_richiesta_ristampa = this.formGroup.value.user_racc_richiesta_ristampa ?? false;

      if (this.tipoRacc.descrizione === 'Cliente Operatore') {
        body.user_racc_menu_ricerche = this.formGroup.value.user_racc_menu_ricerche ?? false;
        body.user_racc_menu_ristampabarcode = this.formGroup.value.user_racc_menu_ristampabarcode ?? false;
        body.user_racc_menu_lotti = this.formGroup.value.user_racc_menu_lotti ?? false;
        body.user_racc_menu_reportclienti = this.formGroup.value.user_racc_menu_reportclienti ?? false;
        if (this._userService.hasAnyRole(['racc_admin', 'racc_supervisore'])) {
          body.user_racc_vede_filtro_stato_su_ricerche = this.formGroup.value.user_racc_vede_filtro_stato_su_ricerche ?? false;
          body.user_racc_vede_segn_assente_su_filtro_segnalazioni_ricerche = this.formGroup.value.user_racc_vede_segn_assente_su_filtro_segnalazioni_ricerche ?? false;
        }
      }
    }

    if (this.formGroup.value.parcel) {
      body.parcel_tipo = this.tipoParcel.id;
    }

    if (this.formGroup.value.atti) {
      body.atti_tipo = this.tipoAtti.id;
    }

    this._fuseProgressBarService.show();
    let response: ApiResponse<User>;
    if (this.user) {
      response = await this._usersApiService.update(this.user.id, body);
    } else {
      response = await this._usersApiService.create(body);
    }
    this._fuseProgressBarService.hide();
    if (response) {
      this._toastr.success(response.message ?? 'Operazione eseguita con successo.', 'Successo!');
      this._location.back();
    }
  }

  public async onDeleteButtonClicked(): Promise<void> {
    const result = await this._dialog.open(SimpleAlertDialogComponent, {
      data: {
        title: 'Cancella Record',
        content: 'Sei sicuro di voler cancellare l\' utente? Verrà cancellato anche il portalettere collegato, se presente',
      }
    }).afterClosed().toPromise();

    if (!result && !this.user) {
      return;
    }

    this._fuseProgressBarService.show();
    let response: ApiResponse<User>;
    if (this.user) {
      response = await this._usersApiService.delete(this.user.id);
    }
    this._fuseProgressBarService.hide();
    if (response) {
      this._toastr.success(response.message ?? 'Operazione eseguita con successo.', 'Successo!');
      console.log('router.url', this.router.url);
      let gestione = 'sdoc';
      if (this.router.url.includes('raccomandate')) {
        gestione = 'raccomandate';
      }
      if (this.router.url.includes('parcel')) {
        gestione = 'parcel';
      }
      if (this.router.url.includes('atti')) {
        gestione = 'atti';
      }
      this.router.navigate(['/', gestione, 'users']);
    }
  }

  public oneGestioneSelected(): boolean {
    return this.formGroup.value.racc ||
      this.formGroup.value.parcel ||
      this.formGroup.value.sdoc ||
      this.formGroup.value.atti;
  }
}
