export interface Corso {
  c_id: number,
  c_data: string,
  c_localita: string,
  c_sede: string,
  c_titolo: string,
  c_relatore: string,
  c_ultimamodifica: string,
  c_attiid: number,

}

export function corsoIdentifier(corso: Corso): number {
  return corso.c_id;
}

export function corsoStringifier(corso: Corso): string {
  return corso.c_titolo;
}
