import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {ApiResponse} from './api-response';
import {Query} from './paginator';
import {Sort} from '@angular/material/sort';
import {environment} from '../../../../environments/environment';

export abstract class ApiService<T> {
  protected readonly url: string;
  public includes: string[] = [];
  public isLoading = new BehaviorSubject<boolean>(false);

  protected constructor(protected http: HttpClient, url: string) {
    this.url = url;
  }

  public async get(id: number|string): Promise<T> {
    this.isLoading.next(true);
    let result = null;
    try {
      result = await this.http.get<T>(`${this.url}/${id}`).toPromise();
    } catch (e) {
    }
    this.isLoading.next(false);
    return result;
  }

  public async create(data: any): Promise<ApiResponse<T>> {
    this.isLoading.next(true);
    let result = null;
    try {
      result = await this.http.post<ApiResponse<T>>(this.url, data).toPromise();
    } catch (e) {
    }
    this.isLoading.next(false);
    return result;
  }

  public async update(id: number|string, data: any): Promise<ApiResponse<T>> {
    this.isLoading.next(true);
    let result = null;
    try {
      result = await this.http.patch<ApiResponse<T>>(`${this.url}/${id}`, data).toPromise();
    } catch (e) {
    }
    this.isLoading.next(false);
    return result;
  }

  public async delete(id: number|string, optionaPars: string = '' ): Promise<ApiResponse<T>> {
    this.isLoading.next(true);
    let result = null;
    try {
      result = await this.http.delete<ApiResponse<T>>(`${this.url}/${id}` + (optionaPars !== '' ? '?' + optionaPars : '')).toPromise();
    } catch (e) {
    }
    this.isLoading.next(false);
    return result;
  }

  public async getExcel(queries: Query[], sort: Sort): Promise<any> {
    this.isLoading.next(true);
    let result = null;
    try {
      result = await this.http.post(this.buildUrlExport(queries, sort), {}, {
        responseType: 'blob'
      }).toPromise();
    } catch (e) {
      console.log(e);
    }
    this.isLoading.next(false);
    return result;
  }

  public async getExcelInPost(queries: Query[], sort: Sort): Promise<any> {
    this.isLoading.next(true);
    let result = null;
    try {
      const data = {};
      if (this.includes?.length > 0) {
        data['include'] = this.includes.join(',');
      }
      if (queries?.length > 0) {
        const filter = {};
        for (const query of queries) {
          filter[query.column] = query.search;
        }
        data['filter'] = filter;
      }
      if (sort && sort.direction) {
        data['sort'] = sort.direction === 'desc' ? '-' : '' + sort.active;
      }
      result = await this.http.post(`${environment.baseUrl}api/sdoc/letture/export`, data, {responseType: 'blob'}).toPromise();
    } catch (e) {
      console.log('errore getExcelInPost', e);
    }
    this.isLoading.next(false);
    return result;
  }

  public async getCsvInPost(queries: Query[], sort: Sort): Promise<any> {
    this.isLoading.next(true);
    let result = null;
    try {
      const data = {};
      if (this.includes?.length > 0) {
        data['include'] = this.includes.join(',');
      }
      if (queries?.length > 0) {
        const filter = {};
        for (const query of queries) {
          filter[query.column] = query.search;
        }
        data['filter'] = filter;
      }
      if (sort && sort.direction) {
        data['sort'] = sort.direction === 'desc' ? '-' : '' + sort.active;
      }
      result = await this.http.post(`${environment.baseUrl}api/sdoc/letture/export/csv`, data, {responseType: 'blob'}).toPromise();
    } catch (e) {
      console.log('errore getCSVInPost', e);
    }
    this.isLoading.next(false);
    return result;
  }

  public buildUrlExport(queries: Query[], sort: Sort): string {
    let url = `${this.url}/export?`;

    if (this.includes?.length > 0) {
      url += `&include=${this.includes.join(',')}`;
    }

    if (queries?.length > 0) {
      for (const query of queries) {
        url += `&filter[${query.column}]=${query.search}`;
      }
    }

    if (sort && sort.direction) {
      url += `&sort=${sort.direction === 'desc' ? '-' : ''}${sort.active}`;
    }

    return url;
  }
}
