import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'truncateText'
})
export class TruncateTextPipe implements PipeTransform {

  transform(value: string, length: number): unknown {
    const elipses = "...";
    if (!value) {
      return value;
    }
    if (value.length <= length) {
      return value;
    }
    let truncatedText = value.slice(0, length);
    return truncatedText + elipses;
  }

}
