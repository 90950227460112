<mat-form-field mat-dialog-title class="w-100-p" appearance="outline">
  <input matInput placeholder="Scrivi per cercare…" [formControl]="formControl">
  <mat-icon *ngIf="!formControl.value" matSuffix>search</mat-icon>
  <button *ngIf="formControl.value"
          mat-icon-button
          matSuffix
          (click)="formControl.setValue('')">
    <mat-icon>clear</mat-icon>
  </button>
</mat-form-field>
<div mat-dialog-content fxLayout="column">
  <mat-progress-bar *ngIf="data.paginator.isLoading | async" color="accent" mode="indeterminate"></mat-progress-bar>

  <mat-table class="main-table" [dataSource]="data.paginator">
    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let entity">
        <mat-checkbox [checked]="selection.isSelected(entity)"
                      (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(entity) : null">
        </mat-checkbox>
      </mat-cell>
    </ng-container>

    <ng-container *ngFor="let column of data.columns" [matColumnDef]="column.column">
      <mat-header-cell *matHeaderCellDef>{{column.label}}</mat-header-cell>
      <mat-cell *matCellDef="let entity">
        <span *ngIf="column.type === 0">{{entity[column.column]}}</span>
        <span *ngIf="column.type === 1">{{entity[column.column] | moment}}</span>
        <mat-checkbox *ngIf="column.type === 2" [checked]="entity[column.column]"></mat-checkbox>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="['select'].concat(data.displayedColumns); sticky: true;"></mat-header-row>
    <mat-row *matRowDef="let entity; columns: ['select'].concat(data.displayedColumns);"
             class="selectable-row"
             (click)="selection.toggle(entity)"></mat-row>
  </mat-table>

  <app-paginator [paginator]="data.paginator"></app-paginator>
</div>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end" fxLayoutGap="16px">
  <mat-checkbox [disabled]="data.paginator.isLoading | async" *ngIf="data.showSelectAll" (change)="selezionaTutti($event.checked)">Seleziona tutti</mat-checkbox>
  <button mat-flat-button mat-dialog-close>Annulla</button>
  <button mat-raised-button color="accent" [disabled]="data.paginator.isLoading | async" (click)="onConfirmButtonClicked()">Conferma</button>
</mat-dialog-actions>
