<ngx-file-drop (onFileDrop)="onFileDropped($event)" multiple="false" [accept]="filters ? filters.join(',') : ''">
  <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
    <div mat-ripple class="h-100-p w-100-p" fxLayout="row" fxLayoutAlign="center center" (click)="openFileSelector()">
      <mat-icon *ngIf="files.length == 0">save_alt</mat-icon>
      <div class="scrollChips h-100-p w-100-p" fxLayoutAlign="center center" *ngIf="files.length > 0">
        <mat-icon *ngIf="files.length > 0">insert_drive_file</mat-icon>
        <mat-chip-list class="mt" [(value)]="files">
          <mat-chip color="accent" *ngFor="let file of files" (removed)="onFileRemoved()">
            {{file?.name}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </div>
      <p *ngIf="files.length === 0" class="mat-title">{{label ? label : 'Trascina qui i tuoi file…'}}</p>
    </div>
  </ng-template>
</ngx-file-drop>
