import {Injectable} from '@angular/core';
import {Paginator} from '../../models/paginator';
import {Cliente} from '../../models/entities/cliente';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientiPaginatorService extends Paginator<Cliente> {
  public includes = ['figli', 'padre'];

  constructor(http: HttpClient) {
    super(http, `${environment.baseUrl}api/clienti`);
  }


}
