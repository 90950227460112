<h1 mat-dialog-title>Attenzione!</h1>
<mat-dialog-content>
  <p>Per la spedizione {{ data.barcode }} è già stata richiesta la ristampa in data</p>
  <ul>
    <li *ngFor="let message of data.date">{{ message | date: 'dd/MM/yyyy' }}
    </li>
  </ul>
  <p>volete procedere con una nuova richiesta di ristampa?</p>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="true">Sì</button>
  <button mat-button [mat-dialog-close]="false">No</button>
</mat-dialog-actions>