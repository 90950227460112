<mat-toolbar class="p-0 mat-elevation-z1">
  <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
      <button
        mat-icon-button
        class="navbar-toggle-button mx-32"
        *ngIf="!hiddenNavbar && !rightNavbar"
        (click)="toggleSidebarOpen('navbar')"
        fxHide.gt-md
      >
        <mat-icon class="secondary-text">menu</mat-icon>
      </button>

      <div
        class="toolbar-separator"
        *ngIf="!hiddenNavbar && !rightNavbar"
        fxHide.xs
        fxHide.gt-md
      ></div>

      <img
        id="logo"
        class="ml-32"
        src="assets/fulmine/common/logo.png"
        alt="logo"
        fxHide.xs
      />

      <span class="mat-caption ml-32" fxHide.lt-lg
        >Benvenuto <b>{{ userService.user?.name }}</b></span
      >
    </div>

    <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
      <button
        mat-button
        class="language-button"
        [matMenuTriggerFor]="systemsMenu"
        fxHide.xs
      >
        <div
          *ngIf="systemsService.selectedSystem == 0"
          fxLayout="row"
          fxLayoutAlign="center center"
        >
          <span class="mr-8">SDOC</span>
          <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
        </div>

        <div
          *ngIf="systemsService.selectedSystem == 1"
          fxLayout="row"
          fxLayoutAlign="center center"
        >
          <span class="mr-8">RACCOMANDATE</span>
          <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
        </div>

        <div
          *ngIf="systemsService.selectedSystem == 2"
          fxLayout="row"
          fxLayoutAlign="center center"
        >
          <span class="mr-8">PARCEL</span>
          <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
        </div>

        <div
          *ngIf="systemsService.selectedSystem == 3"
          fxLayout="row"
          fxLayoutAlign="center center"
        >
          <span class="mr-8">ATTI GIUDIZIARI</span>
          <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
        </div>
      </button>

      <mat-menu #systemsMenu="matMenu" [overlapTrigger]="false">
        <a
          mat-menu-item
          (click)="resetStorage()"
          class="system-menu"
          [class.sdoc]="systemsService.selectedSystem == 0"
          href="#"
          routerLink="/sdoc/dashboard"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <mat-icon *ngIf="systemsService.selectedSystem == 0" color="primary"
            >arrow_right</mat-icon
          >

          <span>SDOC</span>
        </a>

        <a
          mat-menu-item
          (click)="resetStorage()"
          class="system-menu"
          [class.racc]="systemsService.selectedSystem == 1"
          href="#"
          routerLink="/raccomandate/dashboard"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <mat-icon *ngIf="systemsService.selectedSystem == 1" color="primary"
            >arrow_right</mat-icon
          >

          <span>Raccomandate</span>
        </a>

        <a
          mat-menu-item
          class="system-menu"
          [class.parcel]="systemsService.selectedSystem == 2"
          href="https://parcel.fulminegroup.eu/"
          target="_blank"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <mat-icon *ngIf="systemsService.selectedSystem == 2" color="primary"
            >arrow_right</mat-icon
          >

          <span>Parcel</span>
        </a>

        <a
          mat-menu-item
          class="system-menu"
          [class.atti]="systemsService.selectedSystem == 3"
          href="https://attigiudiziari.fulminegroup.eu/"
          target="_blank"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <mat-icon *ngIf="systemsService.selectedSystem == 3" color="primary"
            >arrow_right</mat-icon
          >

          <span>Atti Giudiziari</span>
        </a>
      </mat-menu>

      <div class="toolbar-separator mx-24" fxHide.xs></div>

      <app-notifications *ngIf="userService.user"></app-notifications>

      <div class="toolbar-separator mx-24"></div>

      <app-operazioni *ngIf="userService.user"></app-operazioni>

      <div class="toolbar-separator mx-24"></div>

      <button mat-icon-button class="mr-24" [matMenuTriggerFor]="userMenu">
        <mat-icon>person_outline</mat-icon>
      </button>

      <mat-menu #userMenu>
        <button mat-menu-item routerLink="cambio_password">
          Cambia Password
        </button>
        <button mat-menu-item (click)="logout()">Logout</button>
      </mat-menu>
    </div>
  </div>
</mat-toolbar>
