import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RistampaService {

    constructor(private _http: HttpClient) { }

    async getInfos(id: string, type: string) {
        if (type == 'raccomandate') type = "racc";
        return await this._http.get(environment.nodeUrl + "api/richiesta-ristampa/modulo/" + type + "/" + id)
            .toPromise();
    }

    async send(body: any) {
        return await this._http.post(environment.nodeUrl + "api/richiesta-ristampa/richiesta", body)
            .toPromise();
    }
}