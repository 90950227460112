<div class="page-layout simple fullwidth">

  <!-- HEADER -->
  <div class="header px-64 py-24" fxLayout="row" fxLayoutAlign="space-between center">
    <h2 class="m-0">Richiesta ristampa</h2>

    <div fxLayout="row" fxLayoutGap="16px">
      <button mat-raised-button class="action-button mr-8" color="primary" (click)="back()">
        <mat-icon>arrow_back</mat-icon>
        INDIETRO
      </button>

      <button mat-raised-button class="action-button" color="accent" (click)="save()">
        <mat-icon>save</mat-icon>
        SALVA
      </button>
    </div>

  </div>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <form autocomplete="off" class="content px-64 py-24" fusePerfectScrollbar [formGroup]="formGroup" fxLayout="column">

    <div class="fuse-card auto-width">
      <div class="p-24" fxLayout="column">

        <mat-form-field appearance="outline">
          <mat-label>Nome utente</mat-label>
          <input matInput formControlName="name">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Indirizzo email richiedente</mat-label>
          <input required matInput formControlName="email">
          <mat-error *ngIf="formGroup.controls['email'].hasError('required')">Questo campo è obbligatorio.</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Cliente</mat-label>
          <input matInput formControlName="cliente_nome">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Barcode</mat-label>
          <input matInput formControlName="barcode">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Destinatario</mat-label>
          <input matInput formControlName="destinatario">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Indirizzo</mat-label>
          <input matInput formControlName="indirizzo">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Cap</mat-label>
          <input matInput formControlName="cap">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Località</mat-label>
          <input matInput formControlName="localita">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Provincia</mat-label>
          <input matInput formControlName="provincia">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Messaggio</mat-label>
          <input matInput formControlName="note">
        </mat-form-field>
      </div>
    </div>

  </form>
  <!-- / CONTENT -->

</div>