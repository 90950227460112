import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {Paginator} from "../../models/paginator";
import {Corso} from "../../models/entities/corso";

@Injectable({
  providedIn: 'root'
})
export class CorsoPaginatorService extends Paginator<Corso>{

  constructor(http: HttpClient) {
    super(http, `${environment.baseUrl}api/corsi`);
  }
}
