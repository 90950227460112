<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="0"> <!-- Array -->
    <app-operazione-dettaglio-data-value *ngFor="let subValue of value"
                                         [value]="subValue"></app-operazione-dettaglio-data-value>
  </ng-container>

  <span *ngSwitchCase="1">{{value | json}}</span> <!-- Object -->

  <span *ngSwitchCase="2">{{value}}</span> <!-- Simple -->

  <a target="_blank" *ngSwitchCase="3" href="{{value}}">{{value}}</a> <!-- Url -->
</ng-container>
