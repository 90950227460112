<div class="page-layout simple fullwidth">

  <!-- HEADER -->
  <div class="header px-64 py-24" fxLayout="row" fxLayoutAlign="space-between center">
    <h2 class="m-0">{{portalettere ? portalettere.port_nominativo?.toUpperCase() : 'NUOVO PORTALETTERE'}}</h2>

    <div fxLayout="row" fxLayoutGap="16px">
      <button *ngIf="portalettere && _userService.hasRole('superadmin') || _userService.hasRole('racc_supervisore')"
              mat-raised-button class="action-button"
              [disabled]="(_portalettereApiService.isLoading | async)"
              color="warn" (click)="onDeleteButtonClicked()">
        <mat-icon>delete</mat-icon>
        ELIMINA
      </button>
      <button mat-raised-button class="action-button"
              [disabled]="!formGroup || formGroup?.invalid || !filiale || !oneGestioneSelected() || (_portalettereApiService.isLoading | async)"
              color="accent" (click)="onSaveButtonClicked()">
        <mat-icon>save</mat-icon>
        SALVA
      </button>

    </div>
  </div>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <form autocomplete="off" *ngIf="formGroup" class="content px-64 py-24" fusePerfectScrollbar [formGroup]="formGroup"
        fxLayout="column">

    <!-- Informazioni generali -->
    <div class="fuse-card auto-width">
      <div class="p-24" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="mat-title m-0">Informazioni generali</span>

        <div>
          <mat-checkbox formControlName="port_attivo">Attivo</mat-checkbox>
          <mat-checkbox class="ml-16" formControlName="port_fittizio">Fittizio</mat-checkbox>
        </div>
      </div>

      <div class="p-24" fxLayout="column">

        <div fxLayout="row">
          <mat-form-field class="mr-8" appearance="outline" fxFlex="50">
            <mat-label>Nome</mat-label>
            <input required matInput formControlName="port_nominativo">
          </mat-form-field>
          <mat-form-field class="ml-8" appearance="outline" fxFlex="50">
            <mat-label>Cognome</mat-label>
            <input matInput required formControlName="port_cognome">
          </mat-form-field>
        </div>

        <div fxLayout="row">
          <mat-form-field class="mr-8" appearance="outline" fxFlex="50">
            <mat-label>Codice Fiscale</mat-label>
            <input [readonly]="portalettere?.port_codice_fiscale || portalettere?.port_codice_fiscale?.length > 0"
                   matInput formControlName="port_cod_fiscale">
            <mat-error *ngIf="formGroup.controls['port_cod_fiscale'].hasError('pattern')">
              Il codice Fiscale deve avere un formato italiano valido
            </mat-error>
          </mat-form-field>
          <mat-form-field class="ml-8" appearance="outline" fxFlex="50">
            <mat-label>Tipo contratto</mat-label>
            <input matInput formControlName="port_tipo_contratto">
          </mat-form-field>
        </div>

        <div fxLayout="row">
          <mat-form-field class="mr-8" appearance="outline" fxFlex="50">
            <mat-label>Email</mat-label>
            <input autocomplete="false" matInput type="email" formControlName="port_email"
                   [required]="formGroup?.value.port_attivo"
                   [disabled]="!formGroup?.value.port_racc_firmaelettronica">
            <mat-error>Email non valida</mat-error>
          </mat-form-field>

          <mat-form-field class="ml-8 mr-8" appearance="outline" fxFlex="50">
            <mat-label>Username</mat-label>
            <input autocomplete="false" matInput formControlName="port_username"
                   [required]="formGroup?.value.port_attivo"
                   [disabled]="!formGroup?.value.port_racc_firmaelettronica">
            <mat-error *ngIf="formGroup.controls['port_username'].hasError('maxlength')">
              Lo Username deve essere di massimo 20 caratteri
            </mat-error>
          </mat-form-field>

          <mat-form-field class="ml-8" appearance="outline" fxFlex="50">
            <mat-label>Password</mat-label>
            <input autocomplete="false" [required]="formGroup?.value.port_attivo && !portalettere?.user"
                   matInput [type]="hidePassword ? 'password' : 'text'" formControlName="port_password">
            <mat-icon style="cursor: pointer" matSuffix
                      (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error *ngIf="formGroup.controls['port_password'].hasError('pattern')">
              La password deve avere una lunghezza compresa tra 8 e 30 caratteri,
              e contenere almeno una lettera maiuscola, una minuscola, un numero e un carattere speciale.
            </mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row">
          <mat-form-field class="mr-8" appearance="outline" fxFlex="50">
            <mat-label>Telefono</mat-label>
            <input matInput formControlName="port_telefono">
          </mat-form-field>
          <mat-form-field class="ml-8" appearance="outline" fxFlex="50">
            <mat-label>Indirizzo</mat-label>
            <input matInput formControlName="port_indirizzo">
          </mat-form-field>
        </div>

        <div fxLayout="row">
          <mat-form-field class="mr-8" appearance="outline" fxFlex="50">
            <mat-label>Località</mat-label>
            <input matInput formControlName="port_localita">
          </mat-form-field>
          <mat-form-field class="ml-8" appearance="outline" fxFlex="50">
            <mat-label>CAP</mat-label>
            <input matInput formControlName="port_cap">
          </mat-form-field>
        </div>

        <div fxLayout="row">
          <mat-form-field class="mr-8" appearance="outline" fxFlex="50">
            <mat-label>Provincia</mat-label>
            <input matInput formControlName="port_provincia">
          </mat-form-field>
          <mat-form-field class="ml-8" appearance="outline" fxFlex="50">
            <mat-label>Data acquisto</mat-label>
            <input matInput [matDatepicker]="port_dt_acquisto" formControlName="port_dt_acquisto">
            <mat-datepicker-toggle matSuffix [for]="port_dt_acquisto"></mat-datepicker-toggle>
            <mat-datepicker #port_dt_acquisto></mat-datepicker>
          </mat-form-field>
        </div>

        <div fxLayout="row">
          <mat-form-field class="mr-8" appearance="outline" fxFlex="50">
            <mat-label>Data assunzione</mat-label>
            <input matInput [matDatepicker]="port_dt_assunzione" formControlName="port_dt_assunzione">
            <mat-datepicker-toggle matSuffix [for]="port_dt_assunzione"></mat-datepicker-toggle>
            <mat-datepicker #port_dt_assunzione></mat-datepicker>
          </mat-form-field>

          <app-entity-selector class="ml-8"
                               fxFlex="50"
                               label="Filiale"
                               searchField="fil_nome"
                               required
                               [paginator]="filialePaginator"
                               [value]="filiale"
                               [entityStringifier]="filialeStringifier"
                               [disabled]="_userService.hasRole('racc_filiale')"
                               (valueChanges)="filiale = $event"></app-entity-selector>
        </div>


        <mat-form-field appearance="outline">
          <mat-label>Note</mat-label>
          <textarea matInput formControlName="port_note"></textarea>
        </mat-form-field>

      </div>
    </div>
    <!-- SDOC -->
    <div class="fuse-card auto-width mt-32"
         *ngIf=" _userService.hasRole('superadmin') ||  _userService.hasRole('sdoc_supervisore')">
      <div class="p-24" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="mat-title m-0">Gestione SDOC</span>

        <mat-checkbox formControlName="port_sdoc">Attiva</mat-checkbox>
      </div>
      <fieldset class="p-24 no-border" [disabled]="!formGroup.value.port_sdoc">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
          <mat-form-field *ngIf="portalettere" appearance="outline" fxFlex="50">
            <mat-label>Codice SDOC</mat-label>
            <input [required]="this.formGroup.value.port_sdoc" matInput formControlName="port_sdoc_cod">
            <mat-error *ngIf="formGroup.controls['port_sdoc_cod'].hasError('maxlength')">
              Il Codice deve essere lungo al massimo 5 caratteri
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="50">
            <mat-label>Dispositivo Android ID (SDOC)</mat-label>
            <input matInput type="number" formControlName="port_disp_android_id">
          </mat-form-field>

        </div>
      </fieldset>
    </div>
    <!-- / SDOC -->

    <!-- RACCOMANDATE -->
    <div class="fuse-card auto-width mt-32" *ngIf="_userService.user?.racc ">
      <div class="p-24" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="mat-title m-0">Gestione RACCOMANDATE</span>

        <mat-checkbox formControlName="port_racc">Attiva</mat-checkbox>
      </div>
      <fieldset class="p-24 no-border" [disabled]="!formGroup.value.port_racc">
        <div fxLayout="row">
          <mat-form-field class="mr-8" appearance="outline" fxFlex="50">
            <mat-label>Numero Terminale Raccomandate</mat-label>
            <input matInput formControlName="port_racc_numterm">
          </mat-form-field>

          <mat-form-field
            *ngIf="!portalettere?.port_pinsbloccocertificato && (_userService.hasRole('racc_supervisore') || _userService.hasRole('superadmin'))"
            class="ml-8" appearance="outline"
            fxFlex="50">
            <mat-label>PIN Sblocco certificato</mat-label>
            <input matInput [type]="hidePin ? 'password' : 'text'" formControlName="port_pinsbloccocertificato">
            <mat-icon style="cursor: pointer" matSuffix
                      (click)="hidePin = !hidePin">{{hidePin ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error *ngIf="formGroup.controls['port_pinsbloccocertificato'].hasError('pattern')">
              Il Pin deve avere una lunghezza minima di 6 caratteri, e contenere almeno una lettera maiuscola, una
              minuscola e un numero
            </mat-error>
          </mat-form-field>

          <mat-form-field
            *ngIf="portalettere?.port_statocertificato && (_userService.hasRole('racc_supervisore') || _userService.hasRole('superadmin'))"
            class="ml-8" appearance="outline" fxFlex="50">
            <mat-label>Stato Certificato</mat-label>
            <input matInput readonly formControlName="port_statocertificato">
          </mat-form-field>

        </div>

        <div fxLayout="row" *ngIf="_userService.hasRole('racc_supervisore') || _userService.hasRole('superadmin')">
          <mat-form-field class="mr-8" appearance="outline" fxFlex="50">
            <mat-label>Stato Di Nascita (Sigla)</mat-label>
            <input maxlength="2" matInput formControlName="port_stato_nascita">
            <mat-error *ngIf="formGroup.controls['port_stato_nascita'].hasError('maxlength')">
              Lo stato di nascita deve essere lungo al massimo 2 caratteri
            </mat-error>
          </mat-form-field>

          <mat-checkbox class="mt-16 ml-16" formControlName="port_racc_firmaelettronica">Abilita firma elettronica
          </mat-checkbox>
          <mat-checkbox class="mt-16 ml-16" formControlName="port_consuff"
                        [disabled]="!formGroup.value.port_racc_firmaelettronica">
            Abilita consegna in ufficio
          </mat-checkbox>
        </div>
      </fieldset>
    </div>
    <!-- / RACCOMADNATE -->

    <!-- Parcel -->
    <div class="fuse-card auto-width mt-32" *ngIf="_userService.user?.parcel">
      <div class="p-24" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="mat-title m-0">Gestione PARCEL</span>

        <mat-checkbox formControlName="port_parcel">Attiva</mat-checkbox>
      </div>
      <fieldset class="p-24 no-border" [disabled]="!formGroup.value.port_parcel">

        <mat-form-field class="mr-8" appearance="outline" fxFlex="50">
          <mat-label>Numero Terminale Parcel</mat-label>
          <input matInput formControlName="port_parcel_numterm">
        </mat-form-field>

      </fieldset>
    </div>
    <!-- / Parcel -->

    <!-- Atti -->
    <div class="fuse-card auto-width mt-32" *ngIf="_userService.user?.atti">
      <div class="p-24" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="mat-title m-0">Gestione ATTI</span>

        <mat-checkbox formControlName="port_atti">Attiva</mat-checkbox>
      </div>
      <fieldset class="p-24 no-border" [disabled]="!formGroup.value.port_atti">

        <mat-form-field class="mr-8" appearance="outline" fxFlex="50">
          <mat-label>Numero Terminale Atti</mat-label>
          <input matInput formControlName="port_atti_numterm">
        </mat-form-field>

        <app-entity-selector class="ml-8"
                             fxFlex="50"
                             label="Corso"
                             searchField="c_titolo"
                             [paginator]="corsoPaginator"
                             [value]="corso"
                             [entityStringifier]="corsoStringifier"
                             (valueChanges)="corso = $event"></app-entity-selector>
      </fieldset>
    </div>
    <!-- / Atti -->


    <!-- / Informazioni generali -->

  </form>
  <!-- / CONTENT -->

</div>
