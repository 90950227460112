import {Injectable} from '@angular/core';
import {Paginator} from '../../models/paginator';
import {User} from '../../models/entities/user';
import {environment} from '../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UsersPaginatorService extends Paginator<User> {
  public includes = ['cliente', 'dipendente', 'filiale', 'tipoatti', 'tiporacc', 'tipoparcel', 'tiposdoc'];

  constructor(http: HttpClient) {
    super(http, `${environment.baseUrl}api/utenti`);
  }
}
