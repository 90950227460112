import {TableColumn, TableColumnType} from '../table-column';

export interface Filiale {
  fil_id: number;
  fil_nome: string;
  fil_descrizione: string;
  fil_capoarea: number;
  fil_puoimportare: number;
  fil_indirizzo: string;
  fil_cap: string;
  fil_localita: string;
  fil_provincia: string;
  fil_piva: string;
  fil_gestitadasede: number;
  fil_rendericevute: number;
  fil_email: string;
  fil_email2: string;
  fil_euroroyaltyrete: number;
  fil_codcliarca: string;
  fil_codforarca: string;
  fil_attiva: number;
  fil_gesttariffe: number;
  fil_vedeesitibarcode: number;
  fil_vedefatturazione: number;
  fil_hub_regionale: number;
  fil_hub_nazionale: number;
  fil_scambio_flussi: number;
  fil_vede_royalty_filiale: number;
  fil_token_webservice: string;
  fil_ggcalcolidashboard: number;
  fil_non_smistabile: number;
  fil_tipo: string;
  fil_puntogiacenza: number;
  fil_sdoc: number;
  fil_sdocid: number;
  fil_parcel: number;
  fil_parcelid: number;
  fil_racc: number;
  fil_raccid: number;
  fil_atti: number;
  fil_attiid: number;
  fil_sdoc_madre_C: number;
  fil_sdoc_madre_D: number;
  fil_sdoc_madre_H: number;
  fil_racc_madre_C: number;
  fil_racc_madre_D: number;
  fil_racc_madre_H: number;
  fil_racc_modofatt: string;
  fil_gestesitiposte: number;
  fil_parcel_madre_C: number;
  fil_parcel_madre_D: number;
  fil_parcel_madre_H: number;
  fil_parcel_modofatt_hn: string;
  fil_parcel_modofatt_hr: string;
  fil_parcel_modofatt_fil: string;
  fil_atti_madre_C: number;
  fil_atti_madre_D: number;
  fil_atti_madre_H: number;
  fil_client_id: number;
  fil_client_secret: string;
  madreAttiC: Filiale;
  madreAttiD: Filiale;
  madreAttiH: Filiale;
  figlieAttiC: Filiale[];
  figlieAttiD: Filiale[];
  figlieAttiH: Filiale[];
  madreParcelC: Filiale;
  madreParcelD: Filiale;
  madreParcelH: Filiale;
  figlieParcelC: Filiale[];
  figlieParcelD: Filiale[];
  figlieParcelH: Filiale[];
  madreRaccC: Filiale;
  madreRaccD: Filiale;
  madreRaccH: Filiale;
  figlieRaccC: Filiale[];
  figlieRaccD: Filiale[];
  figlieRaccH: Filiale[];
  madreSdocC: Filiale;
  madreSdocD: Filiale;
  madreSdocH: Filiale;
  figlieSdocC: Filiale[];
  figlieSdocD: Filiale[];
  figlieSdocH: Filiale[];
}

export function filialeIdentifier(filiale: Filiale): number {
  return filiale.fil_id;
}

export function filialeIdentifierSdoc(filiale: Filiale): number {
  return filiale.fil_sdocid;
}

export function filialeIdentifierRacc(filiale: Filiale): number {
  return filiale.fil_raccid;
}

export function filialeStringifier(filiale: Filiale): string {
  return filiale.fil_nome;
}

export const filialeColumnDefinitions: TableColumn[] = [
  {column: 'fil_id', label: 'Id', type: TableColumnType.TEXT},
  {column: 'fil_nome', label: 'Nome', type: TableColumnType.TEXT},
  {column: 'reg_nome', label: 'Regione', type: TableColumnType.TEXT},
  {column: 'prov_nome', label: 'Provincia', type: TableColumnType.TEXT},
  {column: 'capoarea_list', label: 'Capoarea', type: TableColumnType.TEXT},
];

export const filialeDefaultDisplayedColumns: string[] = [
  'fil_id', 'fil_nome',
];

export const filialeConCapoareaDisplayedColumns: string[] = [
  'fil_id', 'fil_nome', 'reg_nome', 'prov_nome', 'capoarea_list'
];

