import {Injectable} from '@angular/core';
import {ApiService} from '../../models/api-service';
import {User} from '../../models/entities/user';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersApiService extends ApiService<User> {

  constructor(http: HttpClient) {
    super(http, `${environment.baseUrl}api/utenti`);
  }
}
