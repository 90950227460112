import {Injectable} from '@angular/core';
import {Paginator} from '../../models/paginator';
import {TipoUtente} from '../../models/entities/tipo-utente';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TipiUtentePaginatorService extends Paginator<TipoUtente> {

  constructor(http: HttpClient) {
    super(http, `${environment.baseUrl}api/tipiutente`);
  }
}
