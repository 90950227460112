import {Component, OnInit} from '@angular/core';
import {
  Filiale,
  filialeColumnDefinitions,
  filialeDefaultDisplayedColumns,
  filialeStringifier
} from '../../../models/entities/filiale';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FuseProgressBarService} from '../../../../../../@fuse/components/progress-bar/progress-bar.service';
import {ActivatedRoute} from '@angular/router';
import {FilialiApiService} from '../../../services/api/filiali-api.service';
import {ToastrService} from 'ngx-toastr';
import {Location} from '@angular/common';
import {FilialiPaginatorService} from '../../../services/paginators/filiali-paginator.service';
import {HttpClient} from '@angular/common/http';
import {ConditionalValidator} from '../../../models/conditional-validator';
import {ApiResponse} from '../../../models/api-response';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-filiale',
  templateUrl: './filiale.component.html',
  styleUrls: ['./filiale.component.scss']
})
export class FilialeComponent implements OnInit {
  public filiale: Filiale;
  public attiMadreC: Filiale;
  public attiMadreCPaginator: FilialiPaginatorService;
  public attiMadreD: Filiale;
  public attiMadreDPaginator: FilialiPaginatorService;
  public attiMadreH: Filiale;
  public attiMadreHPaginator: FilialiPaginatorService;
  public parcelMadreC: Filiale;
  public parcelMadreCPaginator: FilialiPaginatorService;
  public parcelMadreD: Filiale;
  public parcelMadreDPaginator: FilialiPaginatorService;
  public parcelMadreH: Filiale;
  public parcelMadreHPaginator: FilialiPaginatorService;
  public raccMadreC: Filiale;
  public raccMadreCPaginator: FilialiPaginatorService;
  public raccMadreD: Filiale;
  public raccMadreDPaginator: FilialiPaginatorService;
  public raccMadreH: Filiale;
  public raccMadreHPaginator: FilialiPaginatorService;
  public sdocMadreC: Filiale;
  public sdocMadreCPaginator: FilialiPaginatorService;
  public sdocMadreD: Filiale;
  public sdocMadreDPaginator: FilialiPaginatorService;
  public sdocMadreH: Filiale;
  public sdocMadreHPaginator: FilialiPaginatorService;
  public attiFiglieC: Filiale[] = [];
  public attiFiglieCPaginator: FilialiPaginatorService;
  public attiFiglieD: Filiale[] = [];
  public attiFiglieDPaginator: FilialiPaginatorService;
  public attiFiglieH: Filiale[] = [];
  public attiFiglieHPaginator: FilialiPaginatorService;
  public parcelFiglieC: Filiale[] = [];
  public parcelFiglieCPaginator: FilialiPaginatorService;
  public parcelFiglieD: Filiale[] = [];
  public parcelFiglieDPaginator: FilialiPaginatorService;
  public parcelFiglieH: Filiale[] = [];
  public parcelFiglieHPaginator: FilialiPaginatorService;
  public raccFiglieC: Filiale[] = [];
  public raccFiglieCPaginator: FilialiPaginatorService;
  public raccFiglieD: Filiale[] = [];
  public raccFiglieDPaginator: FilialiPaginatorService;
  public raccFiglieH: Filiale[] = [];
  public raccFiglieHPaginator: FilialiPaginatorService;
  public sdocFiglieC: Filiale[] = [];
  public sdocFiglieCPaginator: FilialiPaginatorService;
  public sdocFiglieD: Filiale[] = [];
  public sdocFiglieDPaginator: FilialiPaginatorService;
  public sdocFiglieH: Filiale[] = [];
  public sdocFiglieHPaginator: FilialiPaginatorService;
  public formGroup: FormGroup;

  public filialeStringifier = filialeStringifier;
  public filialeColumnDefinitions = filialeColumnDefinitions;
  public filialeDefaultDisplayedColumns = filialeDefaultDisplayedColumns;

  constructor(public _filialiApiService: FilialiApiService,
              private _formBuilder: FormBuilder,
              private _fuseProgressBarService: FuseProgressBarService,
              private _http: HttpClient,
              private _location: Location,
              private _route: ActivatedRoute,
              private _toastr: ToastrService,
              public _userService: UserService) {
  }

  async ngOnInit(): Promise<void> {
    this.attiMadreCPaginator = new FilialiPaginatorService(this._http);
    this.attiMadreDPaginator = new FilialiPaginatorService(this._http);
    this.attiMadreHPaginator = new FilialiPaginatorService(this._http);
    this.parcelMadreCPaginator = new FilialiPaginatorService(this._http);
    this.parcelMadreDPaginator = new FilialiPaginatorService(this._http);
    this.parcelMadreHPaginator = new FilialiPaginatorService(this._http);
    this.raccMadreCPaginator = new FilialiPaginatorService(this._http);
    this.raccMadreDPaginator = new FilialiPaginatorService(this._http);
    this.raccMadreHPaginator = new FilialiPaginatorService(this._http);
    this.sdocMadreCPaginator = new FilialiPaginatorService(this._http);
    this.sdocMadreDPaginator = new FilialiPaginatorService(this._http);
    this.sdocMadreHPaginator = new FilialiPaginatorService(this._http);
    this.attiFiglieCPaginator = new FilialiPaginatorService(this._http);
    this.attiFiglieDPaginator = new FilialiPaginatorService(this._http);
    this.attiFiglieHPaginator = new FilialiPaginatorService(this._http);
    this.parcelFiglieCPaginator = new FilialiPaginatorService(this._http);
    this.parcelFiglieDPaginator = new FilialiPaginatorService(this._http);
    this.parcelFiglieHPaginator = new FilialiPaginatorService(this._http);
    this.raccFiglieCPaginator = new FilialiPaginatorService(this._http);
    this.raccFiglieDPaginator = new FilialiPaginatorService(this._http);
    this.raccFiglieHPaginator = new FilialiPaginatorService(this._http);
    this.sdocFiglieCPaginator = new FilialiPaginatorService(this._http);
    this.sdocFiglieDPaginator = new FilialiPaginatorService(this._http);
    this.sdocFiglieHPaginator = new FilialiPaginatorService(this._http);

    if (this._route.snapshot.paramMap.has('id')) {
      this._fuseProgressBarService.show();
      this.filiale = await this._filialiApiService.get(parseInt(this._route.snapshot.paramMap.get('id'), 10));
      this.attiMadreC = this.filiale.madreAttiC;
      this.attiMadreD = this.filiale.madreAttiD;
      this.attiMadreH = this.filiale.madreAttiH;
      this.parcelMadreC = this.filiale.madreParcelC;
      this.parcelMadreD = this.filiale.madreParcelD;
      this.parcelMadreH = this.filiale.madreParcelH;
      this.raccMadreC = this.filiale.madreRaccC;
      this.raccMadreD = this.filiale.madreRaccD;
      this.raccMadreH = this.filiale.madreRaccH;
      this.sdocMadreC = this.filiale.madreSdocC;
      this.sdocMadreD = this.filiale.madreSdocD;
      this.sdocMadreH = this.filiale.madreSdocH;
      this.attiFiglieC = this.filiale.figlieAttiC;
      this.attiFiglieD = this.filiale.figlieAttiD;
      this.attiFiglieH = this.filiale.figlieAttiH;
      this.parcelFiglieC = this.filiale.figlieParcelC;
      this.parcelFiglieD = this.filiale.figlieParcelD;
      this.parcelFiglieH = this.filiale.figlieParcelH;
      this.raccFiglieC = this.filiale.figlieRaccC;
      this.raccFiglieD = this.filiale.figlieRaccD;
      this.raccFiglieH = this.filiale.figlieRaccH;
      this.sdocFiglieC = this.filiale.figlieSdocC;
      this.sdocFiglieD = this.filiale.figlieSdocD;
      this.sdocFiglieH = this.filiale.figlieSdocH;
      this._fuseProgressBarService.hide();
    }

    this.formGroup = this._formBuilder.group({
      fil_nome: [
        this.filiale?.fil_nome ?? '',
        [Validators.required, Validators.maxLength(30)]
      ],
      fil_descrizione: [
        this.filiale?.fil_descrizione ?? '',
        [Validators.required, Validators.maxLength(70)]
      ],
      fil_puoimportare: [
        this.filiale?.fil_puoimportare ?? false,
        Validators.required
      ],
      fil_indirizzo: [
        this.filiale?.fil_indirizzo ?? '',
        [Validators.required, Validators.maxLength(50)]
      ],
      fil_cap: [
        this.filiale?.fil_cap ?? '',
        [Validators.required, Validators.maxLength(5)]
      ],
      fil_localita: [
        this.filiale?.fil_localita ?? '',
        [Validators.required, Validators.maxLength(30)]
      ],
      fil_provincia: [
        this.filiale?.fil_provincia ?? '',
        [Validators.required, Validators.maxLength(2)]
      ],
      fil_piva: [
        this.filiale?.fil_piva ?? '',
        Validators.maxLength(16)
      ],
      fil_gestitadasede: [
        this.filiale?.fil_gestitadasede ?? false,
        Validators.required
      ],
      fil_email: [
        this.filiale?.fil_email ?? '',
        [Validators.email, Validators.maxLength(50)]
      ],
      fil_email2: [
        this.filiale?.fil_email2 ?? '',
        [Validators.email, Validators.maxLength(50)]
      ],
      fil_codcliarca: [
        this.filiale?.fil_codcliarca ?? null,
        [Validators.pattern('^[C][0-9]{6}|CNONFAT$'), Validators.maxLength(7)]
      ],
      fil_codforarca: [
        this.filiale?.fil_codforarca ?? null,
        [Validators.required, Validators.pattern('^[F][0-9]{6}|FNONFAT$'), Validators.maxLength(7)]
      ],
      fil_attiva: [
        this.filiale?.fil_attiva ?? true,
        Validators.required
      ],
      fil_gesttariffe: [
        this.filiale?.fil_gesttariffe ?? false,
        Validators.required
      ],
      fil_vedeesitibarcode: [
        this.filiale?.fil_vedeesitibarcode ?? false,
        Validators.required
      ],
      fil_vedefatturazione: [
        this.filiale?.fil_vedefatturazione ?? false,
        Validators.required
      ],
      fil_scambio_flussi: [
        this.filiale?.fil_scambio_flussi ?? false,
        Validators.required
      ],
      fil_vede_royalty_filiale: [
        this.filiale?.fil_vede_royalty_filiale ?? false,
        Validators.required
      ],
      fil_token_webservice: [
        this.filiale?.fil_token_webservice ?? '',
        Validators.maxLength(12)
      ],
      fil_non_smistabile: [
        this.filiale?.fil_non_smistabile ?? false,
        Validators.required
      ],
      fil_client_id: [
        {value: this.filiale?.fil_client_id ?? '', disabled: true}
      ],
      fil_client_secret: [
        {value: this.filiale?.fil_client_secret ?? '', disabled: true}
      ],

      // Systems
      fil_atti: [
        this.filiale?.fil_atti === 1 ?? false,
        Validators.required
      ],
      fil_parcel: [
        this.filiale?.fil_parcel === 1 ?? false,
        Validators.required
      ],
      fil_racc: [
        this.filiale?.fil_racc === 1 ?? false,
        Validators.required
      ],
      fil_sdoc: [
        this.filiale?.fil_sdoc === 1 ?? false,
        Validators.required
      ],

      // Atti
      fil_tipo: [
        this.filiale?.fil_tipo ?? ''
      ],

      // Parcel
      fil_ggcalcolidashboard: [
        this.filiale?.fil_ggcalcolidashboard ?? 0
      ],
      fil_hub_regionale: [
        this.filiale?.fil_hub_regionale === 1 ?? false,
        ConditionalValidator.predicate(() => this.formGroup.value.fil_parcel, Validators.required)
      ],
      fil_hub_nazionale: [
        this.filiale?.fil_hub_nazionale === 1 ?? false,
        ConditionalValidator.predicate(() => this.formGroup.value.fil_parcel, Validators.required)
      ],
      fil_parcel_modofatt_fil: [
        this.filiale?.fil_parcel_modofatt_fil ?? null,
        ConditionalValidator.predicate(() => this.formGroup.value.fil_parcel, Validators.required)
      ],
      fil_parcel_modofatt_hn: [
        this.filiale?.fil_parcel_modofatt_hn ?? null,
        ConditionalValidator.predicate(() => this.formGroup.value.fil_parcel
          && this.formGroup.value.fil_hub_nazionale, Validators.required)
      ],
      fil_parcel_modofatt_hr: [
        this.filiale?.fil_parcel_modofatt_hr ?? null,
        ConditionalValidator.predicate(() => this.formGroup.value.fil_parcel
          && this.formGroup.value.fil_hub_regionale, Validators.required)
      ],

      // Racc
      fil_rendericevute: [
        this.filiale?.fil_rendericevute ?? false,
        ConditionalValidator.predicate(() => this.formGroup.value.fil_racc, Validators.required)
      ],
      fil_gestesitiposte: [
        this.filiale?.fil_gestesitiposte ?? false,
        ConditionalValidator.predicate(() => this.formGroup.value.fil_racc, Validators.required)
      ],
      fil_euroroyaltyrete: [
        this.filiale?.fil_euroroyaltyrete ?? 0
      ],
      fil_racc_modofatt: [
        this.filiale?.fil_racc_modofatt ?? '',
        ConditionalValidator.predicate(() => this.formGroup.value.fil_racc, Validators.required)
      ]
    });
  }

  public async onSaveButtonClicked(): Promise<void> {
    if (this.formGroup.invalid) {
      return;
    }

    const body = this.formGroup.value;
    // ATTI
    body.fil_atti_madre_C = (this.attiMadreC && this.attiMadreC?.fil_id !== this.filiale?.fil_id) ? this.attiMadreC?.fil_id : null;
    body.fil_atti_madre_D = (this.attiMadreD && this.attiMadreD?.fil_id !== this.filiale?.fil_id) ? this.attiMadreD?.fil_id : null;
    body.fil_atti_madre_H = (this.attiMadreH && this.attiMadreH?.fil_id !== this.filiale?.fil_id) ? this.attiMadreH?.fil_id : null;
    body.fil_atti_figlie_C = this.attiFiglieC?.map(f => f.fil_id).filter(f => f !== this.filiale?.fil_id);
    body.fil_atti_figlie_D = this.attiFiglieD?.map(f => f.fil_id).filter(f => f !== this.filiale?.fil_id);
    body.fil_atti_figlie_H = this.attiFiglieH?.map(f => f.fil_id).filter(f => f !== this.filiale?.fil_id);
    // PARCEL
    body.fil_parcel_madre_C = (this.parcelMadreC && this.parcelMadreC?.fil_id !== this.filiale?.fil_id) ? this.parcelMadreC?.fil_id : null;
    body.fil_parcel_madre_D = (this.parcelMadreD && this.parcelMadreD?.fil_id !== this.filiale?.fil_id) ? this.parcelMadreD?.fil_id : null;
    body.fil_parcel_madre_H = (this.parcelMadreH && this.parcelMadreH?.fil_id !== this.filiale?.fil_id) ? this.parcelMadreH?.fil_id : null;
    body.fil_parcel_figlie_C = this.parcelFiglieC?.map(f => f.fil_id).filter(f => f !== this.filiale?.fil_id);
    body.fil_parcel_figlie_D = this.parcelFiglieD?.map(f => f.fil_id).filter(f => f !== this.filiale?.fil_id);
    body.fil_parcel_figlie_H = this.parcelFiglieH?.map(f => f.fil_id).filter(f => f !== this.filiale?.fil_id);
    // RACC
    body.fil_racc_madre_C = (this.raccMadreC && this.raccMadreC?.fil_id !== this.filiale?.fil_id) ? this.raccMadreC?.fil_id : null;
    body.fil_racc_madre_D = (this.raccMadreD && this.raccMadreD?.fil_id !== this.filiale?.fil_id) ? this.raccMadreD?.fil_id : null;
    body.fil_racc_madre_H = (this.raccMadreH && this.raccMadreH?.fil_id !== this.filiale?.fil_id) ? this.raccMadreH?.fil_id : null;
    body.fil_racc_figlie_C = this.raccFiglieC?.map(f => f.fil_id).filter(f => f !== this.filiale.fil_id);
    body.fil_racc_figlie_D = this.raccFiglieD?.map(f => f.fil_id).filter(f => f !== this.filiale.fil_id);
    body.fil_racc_figlie_H = this.raccFiglieH?.map(f => f.fil_id).filter(f => f !== this.filiale.fil_id);
    // SDOC
    body.fil_sdoc_madre_C = (this.sdocMadreC && this.sdocMadreC?.fil_id !== this.filiale?.fil_id) ? this.sdocMadreC?.fil_id : null;
    body.fil_sdoc_madre_D = (this.sdocMadreD && this.sdocMadreD?.fil_id !== this.filiale?.fil_id) ? this.sdocMadreD?.fil_id : null;
    body.fil_sdoc_madre_H = (this.sdocMadreH && this.sdocMadreH?.fil_id !== this.filiale?.fil_id) ? this.sdocMadreH?.fil_id : null;
    body.fil_sdoc_figlie_C = this.sdocFiglieC?.map(f => f.fil_id).filter(f => f !== this.filiale.fil_id);
    body.fil_sdoc_figlie_D = this.sdocFiglieD?.map(f => f.fil_id).filter(f => f !== this.filiale.fil_id);
    body.fil_sdoc_figlie_H = this.sdocFiglieH?.map(f => f.fil_id).filter(f => f !== this.filiale.fil_id);
    // ATTI
    body.fil_puntogiacenza = this.formGroup.value.fil_tipo === 'CPG' || this.formGroup.value.fil_tipo === 'SPG' ? 1 : 0;

    this._fuseProgressBarService.show();
    let response: ApiResponse<Filiale>;
    if (this.filiale) {
      response = await this._filialiApiService.update(this.filiale.fil_id, body);
    } else {
      response = await this._filialiApiService.create(body);
    }
    this._fuseProgressBarService.hide();
    if (response) {
      this._toastr.success(response.message ?? 'Operazione eseguita con successo.', 'Successo!');
      this._location.back();
    }
  }

  public oneGestioneSelected(): boolean {
    return this.formGroup.value.fil_racc ||
      this.formGroup.value.fil_parcel ||
      this.formGroup.value.fil_sdoc ||
      this.formGroup.value.fil_atti;
  }

}
