<div class="page-layout simple right-sidebar inner-scroll">

  <!-- SIDEBAR -->
  <fuse-sidebar class="sidebar filters-sidebar" name="filters-sidebar" position="right">

    <!-- SIDEBAR CONTENT -->
    <div class="content p-24" fusePerfectScrollbar>
      <app-filters [filters]="filters" (filtersChanged)="onFiltersChanged($event)"></app-filters>
    </div>
    <!-- / SIDEBAR CONTENT -->

  </fuse-sidebar>
  <!-- / SIDEBAR -->

  <!-- CENTER -->
  <div class="center" fusePerfectScrollbar>

    <!-- HEADER -->
    <div class="header px-64 py-24" fxLayout="row" fxLayoutAlign="space-between center">
      <h2 class="m-0">ANAGRAFICA CLIENTI</h2>

      <div fxLayout="row" fxLayoutGap="16px">
        <button mat-raised-button class="action-button" color="accent"
                [disabled]="(clientiPaginator.isLoading | async)"
                (click)="toggleSidebar('filters-sidebar')">
          <mat-icon>filter_list</mat-icon>
          FILTRA
        </button>

        <a mat-raised-button class="action-button" color="accent" href="#" routerLink="new"
           [disabled]="(clientiPaginator.isLoading | async)">
          <mat-icon>add</mat-icon>
          NUOVO CLIENTE
        </a>
      </div>
    </div>
    <!-- / HEADER -->


    <!-- CONTENT -->
    <div class="content" fxLayout="column">
      <mat-table class="main-table mx-64 my-24 px-24 pt-10 pb-24"
                 [dataSource]="clientiPaginator"
                 matSort
                 matSortActive="cli_id"
                 matSortDirection="asc"
                 (matSortChange)="clientiPaginator.sort.next($event)"
                 fxFlex>
        <ng-container matColumnDef="cli_id">
          <mat-header-cell mat-sort-header *matHeaderCellDef>Id</mat-header-cell>
          <mat-cell *matCellDef="let cliente">{{cliente.cli_id}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="cli_nome_rag_soc">
          <mat-header-cell mat-sort-header *matHeaderCellDef>Nome</mat-header-cell>
          <mat-cell *matCellDef="let cliente">{{cliente.cli_nome_rag_soc}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="cli_appcod_sdoc">
          <mat-header-cell *matHeaderCellDef>Appcod</mat-header-cell>
          <mat-cell *matCellDef="let cliente">{{cliente.cli_appcod_sdoc}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="cli_ente_sdoc">
          <mat-header-cell *matHeaderCellDef>Ente</mat-header-cell>
          <mat-cell *matCellDef="let cliente">{{cliente.cli_ente_sdoc}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="padre">
          <mat-header-cell *matHeaderCellDef>Padre</mat-header-cell>
          <mat-cell *matCellDef="let cliente">{{cliente.padre?.cli_nome_rag_soc}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="figli">
          <mat-header-cell *matHeaderCellDef>Figli</mat-header-cell>
          <mat-cell *matCellDef="let cliente">
            <mat-chip-list>
              <mat-chip *ngFor="let figlio of cliente.figli">
                {{figlio.cli_nome_rag_soc}}
              </mat-chip>
            </mat-chip-list>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="cli_amministratore">
          <mat-header-cell *matHeaderCellDef>Amministratore</mat-header-cell>
          <mat-cell *matCellDef="let cliente">{{cliente.cli_amministratore}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="cli_indirizzo">
          <mat-header-cell *matHeaderCellDef>Indirizzo</mat-header-cell>
          <mat-cell *matCellDef="let cliente">{{cliente.cli_indirizzo}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="cli_cap">
          <mat-header-cell *matHeaderCellDef>CAP</mat-header-cell>
          <mat-cell *matCellDef="let cliente">{{cliente.cli_cap}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="cli_localita">
          <mat-header-cell *matHeaderCellDef>Località</mat-header-cell>
          <mat-cell *matCellDef="let cliente">{{cliente.cli_localita}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="cli_prov">
          <mat-header-cell *matHeaderCellDef>Provincia</mat-header-cell>
          <mat-cell *matCellDef="let cliente">{{cliente.cli_prov}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="cli_piva">
          <mat-header-cell *matHeaderCellDef>Partita IVA</mat-header-cell>
          <mat-cell *matCellDef="let cliente">{{cliente.cli_piva}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="cli_cf">
          <mat-header-cell *matHeaderCellDef>Codice fiscale</mat-header-cell>
          <mat-cell *matCellDef="let cliente">{{cliente.cli_cf}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="cli_note">
          <mat-header-cell *matHeaderCellDef>Note</mat-header-cell>
          <mat-cell *matCellDef="let cliente">{{cliente.cli_note}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="cli_reportcliente">
          <mat-header-cell *matHeaderCellDef>Report cliente</mat-header-cell>
          <mat-cell *matCellDef="let cliente" class="text-center">
            <mat-checkbox [checked]="cliente.cli_reportcliente" #ckreportcliente (click)="$event.preventDefault();ckreportcliente.checked=cliente.cli_reportcliente"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="cli_codcliarca">
          <mat-header-cell *matHeaderCellDef>Cod. cli. Arca</mat-header-cell>
          <mat-cell *matCellDef="let cliente">{{cliente.cli_codcliarca}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="cli_nome_controllo">
          <mat-header-cell *matHeaderCellDef>Nome cliente controllo</mat-header-cell>
          <mat-cell *matCellDef="let cliente">{{cliente.cli_nome_controllo}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="cli_cod_agente">
          <mat-header-cell *matHeaderCellDef>Cod. Agente</mat-header-cell>
          <mat-cell *matCellDef="let cliente">{{cliente.cli_cod_agente}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="cli_modofatturazione">
          <mat-header-cell *matHeaderCellDef>Modo Fatturazione</mat-header-cell>
          <mat-cell *matCellDef="let cliente">{{modoFatturazione(cliente.cli_modofatturazione)}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="cli_attivo">
          <mat-header-cell *matHeaderCellDef>Attivo</mat-header-cell>
          <mat-cell *matCellDef="let cliente" class="text-center">
            <mat-checkbox [checked]="cliente.cli_attivo" #ckattivo (click)="$event.preventDefault();ckattivo.checked=cliente.cli_attivo"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="cli_spedirenuovi">
          <mat-header-cell *matHeaderCellDef>Spedire solo nuovi</mat-header-cell>
          <mat-cell *matCellDef="let cliente" class="text-center">
            <mat-checkbox [checked]="cliente.cli_spedirenuovi"  #ckspedirenuovi (click)="$event.preventDefault();ckspedirenuovi.checked=cliente.cli_spedirenuovi"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="cli_spediretutto">
          <mat-header-cell *matHeaderCellDef>Spedire tutti esiti</mat-header-cell>
          <mat-cell *matCellDef="let cliente" class="text-center">
            <mat-checkbox [checked]="cliente.cli_spediretutto"  #ckspediretutto (click)="$event.preventDefault();ckspediretutto.checked=cliente.cli_spediretutto"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="cli_ricercanota2">
          <mat-header-cell *matHeaderCellDef>Ricerca nota 2</mat-header-cell>
          <mat-cell *matCellDef="let cliente" class="text-center">
            <mat-checkbox [checked]="cliente.cli_ricercanota2"  #ckricercanota2 (click)="$event.preventDefault();ckricercanota2.checked=cliente.cli_ricercanota2"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="cli_spedisce_dt_compiuta_giac">
          <mat-header-cell *matHeaderCellDef>Sped dt comp. giac.</mat-header-cell>
          <mat-cell *matCellDef="let cliente" class="text-center">
            <mat-checkbox [checked]="cliente.cli_spedisce_dt_compiuta_giac"  #ckspedtcompgiac (click)="$event.preventDefault();ckspedtcompgiac.checked=cliente.cli_spedisce_dt_compiuta_giac"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="cli_spedisce_dt_in_consegna">
          <mat-header-cell *matHeaderCellDef>Sped. dt messa in cons.</mat-header-cell>
          <mat-cell *matCellDef="let cliente" class="text-center">
            <mat-checkbox [checked]="cliente.cli_spedisce_dt_in_consegna"  #ckspedtincons (click)="$event.preventDefault();ckspedtincons.checked=cliente.cli_spedisce_dt_in_consegna"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="cli_esitiposte">
          <mat-header-cell *matHeaderCellDef>Esiti poste</mat-header-cell>
          <mat-cell *matCellDef="let cliente" class="text-center">
            <mat-checkbox [checked]="cliente.cli_esitiposte"  #ckesitiposte (click)="$event.preventDefault();ckesitiposte.checked=cliente.cli_esitiposte"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="cli_parcel_obbligodispacci">
          <mat-header-cell *matHeaderCellDef>Parcel obbligo disp.</mat-header-cell>
          <mat-cell *matCellDef="let cliente" class="text-center">
            <mat-checkbox [checked]="cliente.cli_parcel_obbligodispacci"  #ckparcobbldisp (click)="$event.preventDefault();ckparcobbldisp.checked=cliente.cli_parcel_obbligodispacci()"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="cli_parcel_riceveesitiftp">
          <mat-header-cell *matHeaderCellDef>Parce l ric. esiti FTP</mat-header-cell>
          <mat-cell *matCellDef="let cliente" class="text-center">
            <mat-checkbox [checked]="cliente.cli_parcel_riceveesitiftp"  #ckparcricesftp (click)="$event.preventDefault();ckparcricesftp.checked=cliente.cli_parcel_riceveesitiftp()"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="cli_sdoc_controllo">
          <mat-header-cell *matHeaderCellDef>Controllo</mat-header-cell>
          <mat-cell *matCellDef="let cliente" class="text-center">
            <mat-checkbox [checked]="cliente.cli_sdoc_controllo"  #cksodccontrollo (click)="$event.preventDefault();cksodccontrollo.checked=cliente.cli_sdoc_controllo()"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="cli_sdoc_spedizione">
          <mat-header-cell *matHeaderCellDef>Stored spedizione</mat-header-cell>
          <mat-cell *matCellDef="let cliente" class="text-center">
            <mat-checkbox [checked]="cliente.cli_sdoc_spedizione"  #cksdocspe (click)="$event.preventDefault();cksdocspe.checked=cliente.cli_sdoc_spedizione()"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="cli_sdoc_sportello">
          <mat-header-cell *matHeaderCellDef>Cliente sportello</mat-header-cell>
          <mat-cell *matCellDef="let cliente" class="text-center">
            <mat-checkbox [checked]="cliente.cli_sdoc_sportello"  #cksdocsportello (click)="$event.preventDefault();cksdocsportello.checked=cliente.cli_sdoc_sportello()"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="cli_sdoc_sportello_cod">
          <mat-header-cell *matHeaderCellDef>Codice sportello</mat-header-cell>
          <mat-cell *matCellDef="let cliente">{{cliente.cli_sdoc_sportello_cod}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="updated_at">
          <mat-header-cell *matHeaderCellDef>Ultima modifica</mat-header-cell>
          <mat-cell *matCellDef="let cliente">{{cliente.updated_at | moment}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions" stickyEnd>
          <mat-header-cell *matHeaderCellDef>Azioni</mat-header-cell>
          <mat-cell *matCellDef="let cliente">
            <a mat-icon-button color="accent" href="#" [routerLink]="[cliente.cli_id]">
              <mat-icon>edit</mat-icon>
            </a>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
        <mat-row *matRowDef="let cliente; columns: displayedColumns;"></mat-row>
      </mat-table>

      <div class="h-56 px-64 pb-24" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="mat-caption">@FulmineGroup</span>

        <app-paginator [paginator]="clientiPaginator"></app-paginator>
      </div>
    </div>
    <!-- / CONTENT -->

  </div>
  <!-- / CENTER -->

</div>
