<div class="page-layout simple right-sidebar inner-scroll">

  <!-- SIDEBAR -->
  <fuse-sidebar class="sidebar filters-sidebar" name="filters-sidebar" position="right">

    <!-- SIDEBAR CONTENT -->
    <div class="content p-24" fusePerfectScrollbar>
      <app-filters [filters]="filters" (filtersChanged)="onFiltersChanged($event)"></app-filters>
    </div>
    <!-- / SIDEBAR CONTENT -->

  </fuse-sidebar>
  <!-- / SIDEBAR -->

  <!-- CENTER -->
  <div class="center" fusePerfectScrollbar>

    <!-- HEADER -->
    <div class="header px-64 py-24" fxLayout="row" fxLayoutAlign="space-between center">
      <h2 class="m-0">ANAGRAFICA UTENTI</h2>

      <div fxLayout="row" fxLayoutGap="16px">
        <button mat-raised-button class="action-button" color="accent"
                [disabled]="(usersPaginator.isLoading | async)"
                (click)="toggleSidebar('filters-sidebar')">
          <mat-icon>filter_list</mat-icon>
          FILTRA
        </button>

        <a mat-raised-button class="action-button" color="accent" href="#" routerLink="new"
           [disabled]="(usersPaginator.isLoading | async)">
          <mat-icon>add</mat-icon>
          NUOVO UTENTE
        </a>
      </div>
    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content" fxLayout="column">
      <mat-table class="main-table mx-64 my-24 px-24 pt-10 pb-24"
                 [dataSource]="usersPaginator"
                 matSort
                 matSortActive="id"
                 matSortDirection="asc"
                 (matSortChange)="usersPaginator.sort.next($event)"
                 fxFlex>
        <ng-container matColumnDef="id">
          <mat-header-cell mat-sort-header *matHeaderCellDef>Id</mat-header-cell>
          <mat-cell *matCellDef="let user">{{user.id}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
          <mat-cell *matCellDef="let user">{{user.email}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="username">
          <mat-header-cell mat-sort-header *matHeaderCellDef>Username</mat-header-cell>
          <mat-cell *matCellDef="let user">{{user.username}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="name">
          <mat-header-cell mat-sort-header *matHeaderCellDef>Nome</mat-header-cell>
          <mat-cell *matCellDef="let user">{{user.name}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="cliente">
          <mat-header-cell *matHeaderCellDef>Cliente</mat-header-cell>
          <mat-cell *matCellDef="let user">{{user.cliente?.cli_nome_rag_soc}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="filiale">
          <mat-header-cell *matHeaderCellDef>Filiale</mat-header-cell>
          <mat-cell *matCellDef="let user">{{user.filiale?.fil_nome}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="tiposdoc">
          <mat-header-cell *matHeaderCellDef>Tipo SDOC</mat-header-cell>
          <mat-cell *matCellDef="let user">{{user.tiposdoc?.descrizione}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="tiporacc">
          <mat-header-cell *matHeaderCellDef>Tipo Raccomandate</mat-header-cell>
          <mat-cell *matCellDef="let user">{{user.tiporacc?.descrizione}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="tipoatti">
          <mat-header-cell *matHeaderCellDef>Tipo Atti Giudiziari</mat-header-cell>
          <mat-cell *matCellDef="let user">{{user.tipoatti?.descrizione}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="tipoparcel">
          <mat-header-cell *matHeaderCellDef>Tipo Parcel</mat-header-cell>
          <mat-cell *matCellDef="let user">{{user.tipoparcel?.descrizione}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="sdoc_usa_ricerche">
          <mat-header-cell *matHeaderCellDef>Ricerche</mat-header-cell>
          <mat-cell *matCellDef="let user" class="text-center">
            <mat-checkbox [checked]="user.permissions.includes('sdoc_menu_ricerche')" #ckusaricerche (click)="$event.preventDefault();ckusaricerche.checked=user.permissions.includes('sdoc_menu_ricerche')"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="sdoc_usa_lotti">
          <mat-header-cell *matHeaderCellDef>Lotti</mat-header-cell>
          <mat-cell *matCellDef="let user" class="text-center">
            <mat-checkbox [checked]="user.permissions.includes('sdoc_menu_lotti')" #ckusalotti (click)="$event.preventDefault();ckusalotti.checked=user.permissions.includes('sdoc_menu_lotti')"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="sdoc_usa_affidiclienti">
          <mat-header-cell *matHeaderCellDef>Affidi clienti</mat-header-cell>
          <mat-cell *matCellDef="let user" class="text-center">
            <mat-checkbox [checked]="user.permissions.includes('sdoc_menu_affidiclienti')" #ckusaaffcli (click)="$event.preventDefault();ckusaaffcli.checked=user.permissions.includes('sdoc_menu_affidiclienti')"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="sdoc_usa_reportclienti">
          <mat-header-cell *matHeaderCellDef>Report clienti</mat-header-cell>
          <mat-cell *matCellDef="let user" class="text-center">
            <mat-checkbox [checked]="user.permissions.includes('sdoc_menu_reportclienti')" #ckusarepcli (click)="$event.preventDefault();ckusarepcli.checked=user.permissions.includes('sdoc_menu_reportclienti')"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="sdoc_usa_resi">
          <mat-header-cell *matHeaderCellDef>Report resi</mat-header-cell>
          <mat-cell *matCellDef="let user" class="text-center">
            <mat-checkbox [checked]="user.permissions.includes('sdoc_menu_resi') || user.permissions.includes('sdoc_menu_reportresi')" #ckusaresi (click)="$event.preventDefault();ckusaresi.checked=user.permissions.includes('sdoc_menu_reportresi')"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <!-- <ng-container matColumnDef="sdoc_clisoloricerche">
          <mat-header-cell *matHeaderCellDef>Solo ricerche</mat-header-cell>
          <mat-cell *matCellDef="let user" class="text-center">
            <mat-checkbox [checked]="user.permissions.includes('sdoc_clisoloricerche')" #ckclisoloric (click)="$event.preventDefault();ckclisoloric.checked=user.permissions.includes('sdoc_clisoloricerche')"></mat-checkbox>
          </mat-cell>
        </ng-container> -->
        <ng-container matColumnDef="sdoc_menu_ricerche">
          <mat-header-cell *matHeaderCellDef>Ricerche</mat-header-cell>
          <mat-cell *matCellDef="let user" class="text-center">
            <mat-checkbox [checked]="user.permissions.includes('sdoc_menu_ricerche')" #ckclisoloric (click)="$event.preventDefault();ckclisoloric.checked=user.permissions.includes('sdoc_menu_ricerche')"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="sdoc_ricparzbarc">
          <mat-header-cell *matHeaderCellDef>Perc. barcode</mat-header-cell>
          <mat-cell *matCellDef="let user" class="text-center">
            <mat-checkbox [checked]="user.permissions.includes('sdoc_ricparzbarc')" #ckricparzbcd (click)="$event.preventDefault();ckricparzbcd.checked=user.permissions.includes('sdoc_ricparzbarc')"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="sdoc_ricfattcodut">
          <mat-header-cell *matHeaderCellDef>Cerca cod. ut. e fattura</mat-header-cell>
          <mat-cell *matCellDef="let user" class="text-center">
            <mat-checkbox [checked]="user.permissions.includes('sdoc_ricfattcodut')" #ckricfatcodut (click)="$event.preventDefault();ckricfatcodut.checked=user.permissions.includes('sdoc_ricfattcodut')"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="sdoc_riclocalita">
          <mat-header-cell *matHeaderCellDef>Cerca località</mat-header-cell>
          <mat-cell *matCellDef="let user" class="text-center">
            <mat-checkbox [checked]="user.permissions.includes('sdoc_riclocalita')" #ckricloc (click)="$event.preventDefault();ckricloc.checked=user.permissions.includes('sdoc_riclocalita')"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="sdoc_ricstatosegn">
          <mat-header-cell *matHeaderCellDef>Cerca per stato e segn.</mat-header-cell>
          <mat-cell *matCellDef="let user" class="text-center">
            <mat-checkbox [checked]="user.permissions.includes('sdoc_ricstatosegn')" #ckricstatosegn (click)="$event.preventDefault();ckricstatosegn.checked=user.permissions.includes('sdoc_ricstatosegn')"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="sdoc_conteggidettlotto">
          <mat-header-cell *matHeaderCellDef>Vede conti dett. lotti</mat-header-cell>
          <mat-cell *matCellDef="let user" class="text-center">
            <mat-checkbox [checked]="user.permissions.includes('sdoc_conteggidettlotto')" #ckconteggilotto (click)="$event.preventDefault();ckconteggilotto.checked=user.permissions.includes('sdoc_conteggidettlotto')"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="sdoc_esportaresituttilotti">
          <mat-header-cell *matHeaderCellDef>Esporta resi tutti lotti</mat-header-cell>
          <mat-cell *matCellDef="let user" class="text-center">
            <mat-checkbox [checked]="user.permissions.includes('sdoc_esportaresituttilotti')" #cksdocxpresituttilotti (click)="$event.preventDefault();cksdocxpresituttilotti.checked=user.permissions.includes('sdoc_esportaresituttilotti')"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="sdoc_vede_inlinea">
          <mat-header-cell *matHeaderCellDef>Vede dati in linea</mat-header-cell>
          <mat-cell *matCellDef="let user" class="text-center">
            <mat-checkbox [checked]="user.permissions.includes('sdoc_vede_inlinea')" #cksdocvedelinea (click)="$event.preventDefault();cksdocvedelinea.checked=user.permissions.includes('sdoc_vede_inlinea')"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="sdoc_usa_tariffe">
          <mat-header-cell *matHeaderCellDef>Gestisce tariffe</mat-header-cell>
          <mat-cell *matCellDef="let user" class="text-center">
            <mat-checkbox [checked]="user.permissions.includes('sdoc_usa_tariffe')" #cksdocusatar (click)="$event.preventDefault();cksdocusatar.checked=user.permissions.includes('sdoc_usa_tariffe')"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="sdoc_vede_copertura">
          <mat-header-cell *matHeaderCellDef>Vede copertura</mat-header-cell>
          <mat-cell *matCellDef="let user" class="text-center">
            <mat-checkbox [checked]="user.permissions.includes('sdoc_vede_copertura')" #cksdocvedecop (click)="$event.preventDefault();cksdocvedecop.checked=user.permissions.includes('sdoc_vede_copertura')"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="sdoc_importa">
          <mat-header-cell *matHeaderCellDef>Può importare</mat-header-cell>
          <mat-cell *matCellDef="let user" class="text-center">
            <mat-checkbox [checked]="user.permissions.includes('sdoc_importa')" #cksdocimp (click)="$event.preventDefault();cksdocimp.checked=user.permissions.includes('sdoc_importa')"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions" stickyEnd>
          <mat-header-cell *matHeaderCellDef>Azioni</mat-header-cell>
          <mat-cell *matCellDef="let user">
            <a mat-icon-button color="accent" href="#" [routerLink]="[user.id]">
              <mat-icon>edit</mat-icon>
            </a>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
        <mat-row *matRowDef="let user; columns: displayedColumns;"></mat-row>
      </mat-table>

      <div class="h-56 px-64 pb-24" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="mat-caption">@FulmineGroup</span>

        <app-paginator [paginator]="usersPaginator"></app-paginator>
      </div>
    </div>
    <!-- / CONTENT -->

  </div>
  <!-- / CENTER -->

</div>
