import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { PaginatedResponse } from './paginated-response';
import { HttpClient } from '@angular/common/http';
import { debounceTime, map } from 'rxjs/operators';
import { Sort } from '@angular/material/sort';
import { Lettura } from '../../sdoc/models/entities/lettura';

export abstract class Paginator<T> implements DataSource<T> {
  private _subscription: Subscription;

  protected url: string;
  protected method: string;

  public includes: string[] = [];
  public isLoading = new BehaviorSubject<boolean>(false);
  public page = new BehaviorSubject<PaginatedResponse<T>>(null);
  public queries = new BehaviorSubject<Query[]>(null);
  public sort = new BehaviorSubject<Sort>(null);
  protected lastRequest = new Date();

  protected constructor(private _http: HttpClient, url: string, method: string = 'GET') {
    this.url = url;
    this.method = method;
  }

  connect(collectionViewer: CollectionViewer): Observable<T[] | ReadonlyArray<T>> {
    this.page.next(null);
    this._subscription = combineLatest([this.queries, this.sort]).pipe(debounceTime(500)).subscribe(value => {

      if (value[1] && value[1].active === 'r_id') {
        return;
      }

      if (value[0] || value[1])
        this.loadPage(0, value[0], value[1]);
    });

    return this.page.pipe(map(page => page ? page.data : []));
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this._subscription.unsubscribe();
  }

  public async loadPage(page: number, queries: Query[], sort: Sort): Promise<PaginatedResponse<T>> {
    this.isLoading.next(true);
    // console.log('loadPage queries', queries);
    let response = null;
    if (this.method === 'GET') {
      const url = this.buildUrl(page + 1, queries, sort);
      // console.log('url', url);
      response = await this._http.get<PaginatedResponse<T>>(url).toPromise();
    } else {
      const params = this.buildPars(page + 1, queries, sort);
      response = await this._http.post<PaginatedResponse<T>>(this.url, params).toPromise();
    }
    this.page.next(response);
    this.isLoading.next(false);
    return response;
  }

  public async loadNext(): Promise<PaginatedResponse<T>> {
    const page = this.page.value;
    if (page === null || page.links.next !== null) {
      return await this.loadPage(page?.meta?.current_page ?? -1, this.queries.value, this.sort.value);
    }
    return null;
  }

  public async loadAll(queries: Query[], sort: Sort, loadAll: boolean = false): Promise<T[]> {
    this.isLoading.next(true);

    this.lastRequest = new Date();
    const startRequest = this.lastRequest.getUTCSeconds();

    let result = [];
    let page = 1;
    let pages = 1;
    while (page <= pages && this.lastRequest.getUTCSeconds() === startRequest) {
      this.isLoading.next(true);

      // console.log('loadAll queries', queries);
      let response = null;
      if (this.method === 'GET') {
        response = await this._http.get<PaginatedResponse<T>>(this.buildUrl(page, queries, sort, loadAll)).toPromise();
      } else {
        const params = this.buildPars(page, queries, sort, loadAll);
        response = await this._http.post<PaginatedResponse<T>>(this.url, params).toPromise();
      }
      pages = response.meta.last_page;
      result.push(...response.data);
      page++;
    }
    if (this.lastRequest.getUTCSeconds() !== startRequest) {
      // se mentre stava girando un loadall ne ha lanciato un altro con filtri diversi
      result = [];
    } else {
      this.isLoading.next(false);
    }
    return result;
  }

  private buildUrl(page: number, queries: Query[], sort: Sort, loadAll: boolean = false): string {
    let url = `${this.url}?page=${page}&rows=50`;
    if (url.includes(':4000')) {
      url += `&rows=50`;
    }
    if (this.includes?.length > 0) {
      url += `&include=${this.includes.join(',')}`;
    }

    if (queries?.length > 0) {
      for (const query of queries) {
        if (!query) {
          console.log('query undefined');
        } else {
          url += `&filter[${query.column}]=` + query.search;
        }
      }
    }
    // if (sort && sort.direction && !url.includes(':4000')) {
    //   url += `&sort=${sort.direction === 'desc' ? '-' : ''}${sort.active}`;
    // } else if(sort && sort.direction && url.includes(':4000') ) {
    //     url += `&sort=${sort.direction}`;
    // }
    if (sort && sort.direction) {
      url += `&sort=${sort.direction === 'desc' ? '-' : ''}${sort.active}`;
    }

    if (loadAll) {
      url += `&all=1`;
    }

    return url;
  }

  private buildPars(page: number, queries: Query[], sort: Sort, loadAll: boolean = false): object {


    const params = {
      page: page
    };

    if (this.includes?.length > 0) {
      params['include'] = this.includes.join(',');
    }

    if (queries?.length > 0) {
      const filters = {};
      for (const query of queries) {
        if (query) {
          filters[query.column] = query.search; // typeof query.search === 'string' && query.search.indexOf(',') > 0 ? query.search.split(',') : query.search;
        }
      }
      params['filter'] = filters;
    }

    if (sort && sort.direction) {
      params['sort'] = sort.direction === 'desc' ? '-' : '' + sort.active;
    }

    if (loadAll) {
      params['all'] = 1;
    }
    // console.log('params', params);
    return params;
  }
}

export interface Query {
  column: string;
  search: string | number | boolean;
}
