import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {
  Filiale, filialeStringifier
} from '../../../models/entities/filiale';

import {Regione} from '../../../models/entities/regione';
import {MultiFilialeSelectorDialogComponent} from '../../dialogs/multi-filiale-selector-dialog/multi-filiale-selector-dialog.component';
import {Query} from '../../../models/paginator';
import {Sort} from '@angular/material/sort';

@Component({
  selector: 'filiali-multi-selector',
  templateUrl: './filiali-multi-selector.component.html',
  styleUrls: ['./filiali-multi-selector.component.scss']
})
export class FilialiMultiSelectorComponent implements OnInit {

  public filialeStringifier = filialeStringifier;

  @Input()
  public filters: Query[];

  @Input()
  public regione: Regione;

  @Input()
  public selectedEntities: Filiale[] = [];

  @Input()
  public gestione: string;

  @Output()
  public selectedEntitiesChange = new EventEmitter<Filiale[]>();

  @Input()
  public required = false;

  constructor(
    private dialog: MatDialog,
  ) {
  }

  public capoarea: Filiale[] = null;

  ngOnInit(): void {
  }

  public async onAddButtonClicked(): Promise<void> {
    const result = await this.dialog.open(MultiFilialeSelectorDialogComponent, {
      width: '600px',
      data: {
        selectedEntities: this.selectedEntities,
        gestione: this.gestione,
        filters: this.filters,
        regione: this.regione,
        sort: {active: 'fil_nome', direction: 'asc'}
      }
    }).afterClosed().toPromise();
    if (result) {
      this.selectedEntities.push(result);
      this.selectedEntitiesChange.emit(result);
    }
  }

  public onReset(): void {
    this.selectedEntities = [];
    this.selectedEntitiesChange.emit([]);
  }

  public onEntityRemoved(index: number): void {
    this.selectedEntities.splice(index, 1);
    this.selectedEntitiesChange.emit(this.selectedEntities);
  }

}
