import {Injectable} from '@angular/core';
import {ApiService} from '../../models/api-service';
import {Filiale} from '../../models/entities/filiale';
import {environment} from '../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Types} from "../../models/entities/system";

@Injectable({
  providedIn: 'root'
})
export class FilialiApiService extends ApiService<Filiale> {

  constructor(http: HttpClient) {
    super(http, `${environment.baseUrl}api/filiali`);
  }

  public async getFilialeFormTipo(id: number | string, tipo: Types): Promise<Filiale> {
    this.isLoading.next(true);
    let result = null;
    try {
      result = await this.http.get<Filiale>(`${this.url}/${id}/${tipo}`).toPromise();
    } catch (e) {
    }
    this.isLoading.next(false);
    return result;
  }
}
