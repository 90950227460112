import {Component, OnDestroy, OnInit} from '@angular/core';
import {FuseProgressBarService} from '../../../../../../@fuse/components/progress-bar/progress-bar.service';
import {BehaviorSubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {FuseSidebarService} from '../../../../../../@fuse/components/sidebar/sidebar.service';
import {ColumnFilter, ColumnFilterType, RelationFilter} from '../../partials/filters/filters.component';
import {Query} from '../../../models/paginator';
import {System} from '../../../models/entities/system';
import {SystemsService} from '../../../services/systems.service';
import {HttpClient} from '@angular/common/http';
import {PortaletterePaginatorService} from '../../../services/paginators/portalettere-paginator.service';
import {FilialiPaginatorService} from '../../../services/paginators/filiali-paginator.service';
import {filialeIdentifier, filialeStringifier} from '../../../models/entities/filiale';

@Component({
  selector: 'app-terminali-postini',
  templateUrl: './portalettere-index.component.html',
  styleUrls: ['./portalettere-index.component.scss']
})
export class PortalettereIndexComponent implements OnInit, OnDestroy {
  private _queries = new BehaviorSubject<Query[]>(null);
  private systemQuery: Query;
  private _unsubscribeAll: Subject<any>;

  public displayedColumns: string[];
  public filters: (ColumnFilter | RelationFilter<any>)[];

  constructor(private _fuseProgressBarService: FuseProgressBarService,
              private _fuseSidebarService: FuseSidebarService,
              private _http: HttpClient,
              private _systemsService: SystemsService,
              public portaletterePaginator: PortaletterePaginatorService) {
  }

  ngOnInit(): void {
    this._unsubscribeAll = new Subject();

    this.portaletterePaginator.isLoading.pipe(takeUntil(this._unsubscribeAll)).subscribe(isLoading => {
      if (isLoading) {
        this._fuseProgressBarService.show();
      } else {
        this._fuseProgressBarService.hide();
      }
    });

    this.filters = [
      {
        label: 'Cognome',
        column: 'port_cognome',
        type: ColumnFilterType.STRING,
      }, {
        label: 'Nome',
        column: 'port_nominativo',
        type: ColumnFilterType.STRING,
      },
      {
        label: 'Attivi',
        column: 'port_attivo',
        type: ColumnFilterType.ARRAY, values: [
          {label: 'Tutti', value: null},
          {label: 'Sì', value: true},
          {label: 'No', value: false}
        ],
        defaultValue: null
      },
      {
        label: 'Filiale',
        column: 'port_filid',
        paginator: new FilialiPaginatorService(this._http),
        searchField: 'fil_nome',
        entityIdentifier: filialeIdentifier,
        entityStringifier: filialeStringifier,
      },
      {
        label: 'Gestione',
        column: 'gestione',
        type: ColumnFilterType.ARRAY, values: [
          {label: 'SDOC', value: 'sdoc'},
          {label: 'Raccomandate', value: 'racc'},
          {label: 'Parcel', value: 'parcel'},
          {label: 'Atti Giudiziari', value: 'atti'}
        ],
        defaultValue: {column: 'gestione', search: System[this._systemsService.selectedSystem]}
      },
    ];

    this.systemQuery = {column: 'gestione', search: System[this._systemsService.selectedSystem]};
    this.portaletterePaginator.queries.next([this.systemQuery, {column: 'non_fittizi', search: 1}]);
    this._queries.pipe(takeUntil(this._unsubscribeAll)).subscribe(queries => {
      this.portaletterePaginator.queries.next([
        ...(queries ?? []),
        this.systemQuery,
        {column: 'non_fittizi', search: 1}
      ]);
    });

    switch (this._systemsService.selectedSystem) {
      case System.ATTI:
        // tslint:disable-next-line:max-line-length
        this.displayedColumns = ['port_id', 'port_atti_numterm', 'port_dt_acquisto', 'port_note', 'port_cognome', 'port_nominativo', 'port_dt_assunzione', 'port_tipo_contratto', 'port_indirizzo', 'port_cap', 'port_localita', 'port_provincia', 'port_attivo', 'filiale', 'actions'];
        break;

      case System.PARCEL:
        // tslint:disable-next-line:max-line-length
        this.displayedColumns = ['port_id', 'port_parcel_numterm', 'port_dt_acquisto', 'port_note', 'port_cognome', 'port_nominativo', 'port_dt_assunzione', 'port_tipo_contratto', 'port_indirizzo', 'port_cap', 'port_localita', 'port_provincia', 'port_attivo', 'filiale', 'actions'];
        break;

      case System.RACC:
        // tslint:disable-next-line:max-line-length
        this.displayedColumns = ['port_id', 'port_racc_numterm', 'port_dt_acquisto', 'port_note', 'port_cognome', 'port_nominativo', 'port_dt_assunzione', 'port_tipo_contratto', 'port_indirizzo', 'port_cap', 'port_localita', 'port_provincia', 'port_attivo', 'filiale', 'actions'];
        break;

      case System.SDOC:
        // tslint:disable-next-line:max-line-length
        this.displayedColumns = ['port_id', 'port_sdoc_cod', 'port_dt_acquisto', 'port_note', 'port_cognome', 'port_nominativo', 'port_dt_assunzione', 'port_tipo_contratto', 'port_indirizzo', 'port_cap', 'port_localita', 'port_provincia', 'port_attivo', 'filiale', 'actions'];
        break;
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  public toggleSidebar(name): void {
    this._fuseSidebarService.getSidebar(name).toggleOpen();
  }

  public onFiltersChanged(queries: Query[]): void {
    const queryGestione = queries.filter(query => (query.column === 'gestione'));
    if (queryGestione) {
      this.systemQuery = queryGestione[0];
    }
    this._queries.next(queries);
  }
}

