<div class="page-layout simple fullwidth">

  <!-- HEADER -->
  <div class="header px-64 py-24" fxLayout="row" fxLayoutAlign="space-between center">
    <h2 class="m-0">{{user ? user.name.toUpperCase() : 'NUOVO UTENTE'}}</h2>

    <div fxLayout="row" fxLayoutGap="16px">
      <button
        *ngIf="user && _userService.hasAnyRole(['superadmin','racc_supervisore']) || (user && _userService.user.id == user.creatoreid) "
        mat-raised-button class="action-button" [disabled]="(_usersApiService.isLoading | async)" color="warn"
        (click)="onDeleteButtonClicked()">
        <mat-icon>delete</mat-icon>
        ELIMINA
      </button>

      <button mat-raised-button class="action-button" color="accent" (click)="onSaveButtonClicked()"
        [disabled]="!formGroup || formGroup.invalid || !oneGestioneSelected() || (_usersApiService.isLoading | async)">
        <mat-icon>save</mat-icon>
        SALVA
      </button>
    </div>

  </div>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <form autocomplete="off" *ngIf="formGroup" class="content px-64 py-24" fusePerfectScrollbar [formGroup]="formGroup"
    fxLayout="column">

    <!-- Informazioni generali -->
    <div class="fuse-card auto-width">
      <div class="p-24" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="mat-title m-0">Informazioni generali</span>

        <mat-checkbox formControlName="attivo">Attivo</mat-checkbox>
      </div>

      <div class="p-24">
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-form-field appearance="outline" fxFlex="50" class="mr-8">
            <mat-label>Username</mat-label>
            <input required matInput formControlName="username" maxlength="191">
            <mat-error>Questo campo è obbligatorio.</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="50" class="ml-8">
            <mat-label>Nome</mat-label>
            <input required matInput formControlName="name" maxlength="191">
            <mat-error>Questo campo è obbligatorio.</mat-error>
          </mat-form-field>
        </div>

        <div class="mt-16" fxLayout="row" fxLayoutAlign="start center">
          <mat-form-field appearance="outline" fxFlex="33" class="mr-8">
            <mat-label>Email</mat-label>
            <input autocomplete="false" required matInput type="email" formControlName="email" maxlength="191">
            <mat-error>Email non valida</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="33" class="ml-8 mr-8">
            <mat-label>Password</mat-label>
            <input autocomplete="false" matInput [type]="hidePassword ? 'password' : 'text'" formControlName="password"
              maxlength="30">
            <mat-icon style="cursor: pointer" matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ?
              'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error *ngIf="formGroup.controls['password'].hasError('required')">Questo campo è obbligatorio.
            </mat-error>
            <mat-error *ngIf="formGroup.controls['password'].hasError('pattern')">La password deve avere una lunghezza
              compresa tra 8 e 30 caratteri, e contenere almeno una lettera maiuscola, una minuscola, un numero e un
              carattere speciale.
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex="33" class="ml-8">
            <mat-label>Conferma Password</mat-label>
            <input autocomplete="false" matInput [type]="hideConfirmPassword ? 'password' : 'text'"
              formControlName="password_confirmation" maxlength="30">
            <mat-icon style="cursor: pointer" matSuffix
              (click)="hideConfirmPassword = !hideConfirmPassword">{{hideConfirmPassword ? 'visibility_off' :
              'visibility'}}</mat-icon>
            <mat-error *ngIf="formGroup.controls['password_confirmation'].hasError('required')">Questo campo è
              obbligatorio.
            </mat-error>
            <mat-error *ngIf="formGroup.controls['password_confirmation'].hasError('NoPasswordMatch')">La Password e la
              Conferma Password non corrispondono
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <!-- / Informazioni generali -->

    <!-- SDOC -->
    <div class="fuse-card auto-width mt-32" *ngIf="_userService.user?.sdoc">
      <div class="p-24" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="mat-title m-0">Gestione SDOC</span>

        <mat-checkbox formControlName="sdoc">Attiva</mat-checkbox>
      </div>

      <fieldset class="p-24 no-border" [disabled]="!formGroup.value.sdoc">

        <app-entity-selector label="Tipo utente" searchField="descrizione" required="true" [mode]="'select'"
          [disabled]="!formGroup.value.sdoc" [paginator]="tipoSdocPaginator"
          [filters]="tipiFilter.concat([{column: 'gestione', search: 'sdoc'}])" [value]="tipoSdoc"
          [entityStringifier]="tipoUtenteStringifier" (valueChanges)="tipoSdoc = $event"></app-entity-selector>

        <div class="mt-16" fxLayout="row wrap" fxLayoutGap="32px">
          <mat-checkbox formControlName="sdoc_menu_reportresi"
            *ngIf="_userService.hasAnyRole(['sdoc_admin','sdoc_supervisore']) || _userService.user.permissions?.includes('sdoc_menu_reportresi')">Può
            visualizzare il menu resi</mat-checkbox>

          <mat-checkbox formControlName="sdoc_ricparzbarc"
            *ngIf="_userService.hasAnyRole(['sdoc_admin','sdoc_supervisore'])">Può effettuare ricerche su parte del
            barcodedatamatrix nello
            Sdoc
          </mat-checkbox>

          <mat-checkbox formControlName="sdoc_clisoloricerche"
            *ngIf="_userService.hasAnyRole(['sdoc_admin','sdoc_supervisore'])">Può vedere solo il menu ricerche dello
            Sdoc (sovrascrive
            altri permessi sdoc)
          </mat-checkbox>

          <mat-checkbox formControlName="sdoc_ricfattcodut"
            *ngIf="_userService.hasAnyRole(['sdoc_admin','sdoc_supervisore'])">Può cercare codice utente e fattura nello
            Sdoc
          </mat-checkbox>

          <mat-checkbox formControlName="sdoc_riclocalita"
            *ngIf="_userService.hasAnyRole(['sdoc_admin','sdoc_supervisore'])">Può cercare Località nello
            Sdoc</mat-checkbox>

          <mat-checkbox formControlName="sdoc_menu_ricerche"
            *ngIf="_userService.hasAnyRole(['sdoc_admin','sdoc_supervisore']) || _userService.user.permissions?.includes('sdoc_menu_ricerche')">Vede
            il menu ricerche dello Sdoc</mat-checkbox>

          <mat-checkbox formControlName="sdoc_menu_lotti"
            *ngIf="_userService.hasAnyRole(['sdoc_admin','sdoc_supervisore']) || _userService.user.permissions?.includes('sdoc_menu_lotti')">Vede
            il menu lotti dello sdoc</mat-checkbox>

          <mat-checkbox formControlName="sdoc_menu_affidiclienti"
            *ngIf="_userService.hasAnyRole(['sdoc_admin','sdoc_supervisore']) || _userService.user.permissions?.includes('sdoc_menu_affidiclienti')">Vede
            il menu affidi clienti dello Sdoc</mat-checkbox>

          <mat-checkbox formControlName="sdoc_menu_reportclienti"
            *ngIf="_userService.hasAnyRole(['sdoc_admin','sdoc_supervisore']) || _userService.user.permissions?.includes('sdoc_menu_reportclienti')">Vede
            il menu report clienti dello Sdoc</mat-checkbox>

          <mat-checkbox formControlName="sdoc_cerca_stato_segn"
            *ngIf="_userService.hasAnyRole(['sdoc_admin','sdoc_supervisore'])">Può cercare per Stato e Segnalazione
            nello Sdoc
          </mat-checkbox>

          <mat-checkbox formControlName="sdoc_conteggidettlotto"
            *ngIf="_userService.hasAnyRole(['sdoc_admin','sdoc_supervisore'])">Vede conteggi dettagliati lotto nello
            Sdoc
          </mat-checkbox>

          <mat-checkbox formControlName="sdoc_menu_utenti"
            *ngIf="_userService.hasAnyRole(['sdoc_admin','sdoc_supervisore']) || _userService.user.permissions?.includes('sdoc_menu_utenti')">Vede
            il menu utenti</mat-checkbox>

          <mat-checkbox formControlName="sdoc_vede_inlinea"
            *ngIf="_userService.hasAnyRole(['sdoc_admin','sdoc_supervisore'])">Vede dati in linea dello
            sdoc</mat-checkbox>

          <mat-checkbox formControlName="sdoc_esportaresituttilotti"
            *ngIf="_userService.hasAnyRole(['sdoc_admin','sdoc_supervisore'])">Può estrarre i resi di tutti i lotti
            dello sdoc
          </mat-checkbox>

          <mat-checkbox formControlName="sdoc_usa_tariffe"
            *ngIf="_userService.hasAnyRole(['sdoc_admin','sdoc_supervisore'])">Gestisce le Tariffe dello
            sdoc</mat-checkbox>

          <mat-checkbox formControlName="sdoc_importa"
            *ngIf="_userService.hasAnyRole(['sdoc_admin','sdoc_supervisore'])">Può importare nello Sdoc</mat-checkbox>

          <mat-checkbox formControlName="sdoc_menu_coperturacap"
            *ngIf="_userService.hasAnyRole(['sdoc_admin','sdoc_supervisore'])">Vede menu copertura CAP dello
            Sdoc</mat-checkbox>

          <mat-checkbox formControlName="sdoc_vede_fattura_su_resi"
            *ngIf="_userService.hasAnyRole(['sdoc_admin','sdoc_supervisore'])">Vede Fattura su Report Resi dello Sdoc
          </mat-checkbox>

          <mat-checkbox formControlName="sdoc_vede_codutente_su_resi"
            *ngIf="_userService.hasAnyRole(['sdoc_admin','sdoc_supervisore'])">Vede Codice Utente su Report Resi dello
            Sdoc
          </mat-checkbox>
          <mat-checkbox formControlName="sdoc_richiesta_ristampa"
            *ngIf="_userService.hasAnyRole(['sdoc_admin','sdoc_supervisore'])">Abilita ristampa
          </mat-checkbox>
          <mat-checkbox formControlName="sdoc_menu_report_lotti_aqp"
          *ngIf="_userService.hasAnyRole(['sdoc_admin','sdoc_supervisore'])">Abilita report AQP
        </mat-checkbox>
        </div>

        <div class="mt-16">
          <app-entity-selector class="mr-16" fxFlex="30" label="Clienti venditore" searchField="cli_nome_rag_soc"
            [required]="tipoSdoc?.clienti==1" [disabled]="!tipoSdoc || tipoSdoc?.clienti!=1"
            [paginator]="clientiVenditorePaginator" [filters]="[{column: 'gestione', search: 'sdoc'}]"
            [entityStringifier]="clienteStringifier"
            (valueChanges)="$event && !clientiVenditore?.includes($event) ? clientiVenditore?.push($event) : null"></app-entity-selector>

          <mat-chip-list fxFlex="70">
            <mat-chip *ngFor="let cliente of clientiVenditore; let i = index" (removed)="clientiVenditore.splice(i, 1)">
              {{cliente.cli_nome_rag_soc}}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
          </mat-chip-list>
        </div>
      </fieldset>
    </div>
    <!-- / SDOC -->

    <!-- Raccomandate -->
    <div class="fuse-card auto-width mt-32" *ngIf="_userService.user?.racc">
      <div class="p-24" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="mat-title m-0">Gestione Raccomandate</span>

        <mat-checkbox formControlName="racc">Attiva</mat-checkbox>
      </div>

      <fieldset class="p-24 no-border" [disabled]="!formGroup.value.racc">
        <app-entity-selector label="Tipo utente" searchField="descrizione" required="true" [mode]="'select'"
          [disabled]="!formGroup.value.racc" [paginator]="tipoRaccPaginator"
          [filters]="tipiFilter.concat([{column: 'gestione', search: 'racc'}])" [value]="tipoRacc"
          [entityStringifier]="tipoUtenteStringifier" (valueChanges)="tipoRacc = $event"></app-entity-selector>

        <div *ngIf="formGroup.value.racc && tipoRacc?.descrizione === 'Cliente Operatore'" class="mt-16"
          fxLayout="row wrap" fxLayoutGap="32px">
          <!--
            ai cliente_operatore non permetto di creare utenti
            su racc non c'è un menu report clienti ed un permesso racc_menu_report_clienti
          -->
          <mat-checkbox formControlName="user_racc_menu_ricerche"
            *ngIf="_userService.hasAnyRole( ['racc_admin', 'racc_supervisore']) || _userService.user.permissions?.includes('racc_menu_ricerche')">Può
            visualizzare il menu ricerche</mat-checkbox>
          <mat-checkbox formControlName="user_racc_menu_ristampabarcode"
            *ngIf="_userService.hasAnyRole( ['racc_admin', 'racc_supervisore']) || _userService.user.permissions?.includes('racc_menu_ristampabarcode')">Può
            visualizzare il menu ristampa barcode</mat-checkbox>
          <mat-checkbox formControlName="user_racc_menu_lotti"
            *ngIf="_userService.hasAnyRole( ['racc_admin', 'racc_supervisore']) || _userService.user.permissions?.includes('racc_menu_lotti')">Può
            visualizzare il menu lotti</mat-checkbox>
          <mat-checkbox formControlName="user_racc_menu_reportclienti"
            *ngIf="_userService.hasAnyRole( ['racc_admin', 'racc_supervisore']) || _userService.user.permissions?.includes('racc_menu_reportclienti')">Può
            visualizzare il menu report clienti</mat-checkbox>

          <mat-checkbox formControlName="user_racc_vede_filtro_stato_su_ricerche"
            *ngIf="_userService.hasAnyRole( ['racc_admin', 'racc_supervisore'])">Vede filtro stato su
            ricerche</mat-checkbox>
          <mat-checkbox formControlName="user_racc_vede_segn_assente_su_filtro_segnalazioni_ricerche"
            *ngIf="_userService.hasAnyRole( ['racc_admin', 'racc_supervisore'])">Vede segnalazione assente su
            ricerche</mat-checkbox>
        </div>

        <mat-checkbox formControlName="user_racc_richiesta_ristampa">Abilita ristampa
        </mat-checkbox>

      </fieldset>

    </div>

    <!-- / Raccomandate -->

    <!-- Parcel -->
    <div class="fuse-card auto-width mt-32" *ngIf="_userService.user?.parcel">
      <div class="p-24" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="mat-title m-0">Gestione Parcel</span>

        <mat-checkbox formControlName="parcel">Attiva</mat-checkbox>
      </div>

      <fieldset class="p-24 no-border" [disabled]="!formGroup.value.parcel">
        <app-entity-selector label="Tipo utente" searchField="descrizione" [mode]="'select'"
          [disabled]="!formGroup.value.parcel" [paginator]="tipoParcelPaginator"
          [filters]="tipiFilter.concat([{column: 'gestione', search: 'parcel'}])" [value]="tipoParcel"
          [entityStringifier]="tipoUtenteStringifier" (valueChanges)="tipoParcel = $event"></app-entity-selector>
      </fieldset>
    </div>
    <!-- / Parcel -->

    <!-- Atti -->
    <div class="fuse-card auto-width mt-32" *ngIf="_userService.user?.atti">
      <div class="p-24" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="mat-title m-0">Gestione Atti Giudiziari</span>

        <mat-checkbox formControlName="atti">Attiva</mat-checkbox>
      </div>

      <fieldset class="p-24 no-border" [disabled]="!formGroup.value.atti">
        <app-entity-selector label="Tipo utente" searchField="descrizione" required="true" [mode]="'select'"
          [disabled]="!formGroup.value.atti" [paginator]="tipoAttiPaginator"
          [filters]="tipiFilter.concat([{column: 'gestione', search: 'atti'}])" [value]="tipoAtti"
          [entityStringifier]="tipoUtenteStringifier" (valueChanges)="tipoAtti = $event"></app-entity-selector>
      </fieldset>
    </div>
    <!-- / Atti -->

    <!-- Informazioni aggiuntive -->
    <div class="fuse-card auto-width mt-32">
      <div class="p-24">
        <span class="mat-title m-0">Informazioni aggiuntive</span>
      </div>

      <div class="p-24">
        <app-entity-selector label="Cliente" searchField="cli_nome_rag_soc" [paginator]="clientePaginator"
          [disabled]="!isCliente" [filters]="[gestioniFilter]" [value]="cliente"
          [entityStringifier]="clienteStringifier" (valueChanges)="cliente = $event"></app-entity-selector>

        <app-entity-selector class="mt-16" label="Filiale" searchField="fil_nome" [paginator]="filialePaginator"
          [disabled]="!isFiliale" [filters]="[gestioniFilter]" [value]="filiale"
          [entityStringifier]="filialeStringifier" (valueChanges)="filiale = $event"></app-entity-selector>
      </div>
    </div>
    <!-- / Informazioni aggiuntive -->

  </form>
  <!-- / CONTENT -->

</div>