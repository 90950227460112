import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import {
  ColumnFilter,
  ColumnFilterType,
  RelationFilter
} from '../../../../common/components/partials/filters/filters.component';
import { FuseProgressBarService } from '../../../../../../@fuse/components/progress-bar/progress-bar.service';
import { FuseSidebarService } from '../../../../../../@fuse/components/sidebar/sidebar.service';
import { takeUntil } from 'rxjs/operators';
import { ManutenzionePaginatorService } from 'app/fulmine/common/services/paginators/manutenzione-paginator.service';
import { ManutenzioneApiService } from 'app/fulmine/common/services/api/manutenzione-api.service';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';

@Component({
  selector: 'app-manutenzione',
  templateUrl: './manutenzione.component.html',
  styleUrls: ['./manutenzione.component.scss']
})
export class ManutenzioneComponent implements OnInit, OnDestroy {
  @ViewChild('whereRef', { static: false }) whereRef: ElementRef<HTMLInputElement>;


  private _unsubscribeAll: Subject<any>;

  public displayedColumns = ['db', 'table_name', 'table_rows', 'prod_num_rows', 'last_updated', 'status', 'query', 'actions'];
  public filters: (ColumnFilter | RelationFilter<any>)[];

  private subject: WebSocketSubject<any>;

  private inputWhereClause: any[]

  constructor(private _fuseProgressBarService: FuseProgressBarService,
    private _fuseSidebarService: FuseSidebarService,
    public manutenzionePaginator: ManutenzionePaginatorService, public manutenzioneApiService: ManutenzioneApiService) {
    this.inputWhereClause = []
    this.subject = webSocket('ws://backendtest.fulminegroup.eu:8080');
  }

  ngOnInit(): void {


    this._unsubscribeAll = new Subject();

    this.manutenzionePaginator.isLoading.pipe(takeUntil(this._unsubscribeAll)).subscribe(isLoading => {
      if (isLoading) {
        this._fuseProgressBarService.show();
      } else {
        this._fuseProgressBarService.hide();
      }
    });

    this.filters = [
      {
        label: 'db',
        column: 'db',
        type: ColumnFilterType.ARRAY, values: [
          { label: 'Central', value: 'central' },
          { label: 'SDOC', value: 'SDOC' },
          { label: 'Raccomandate', value: 'Raccomandate-Lektor' },
          { label: 'Atti giudiziari', value: 'attigiudiziari' },
          { label: 'Parcel', value: 'parcel' },
          { label: 'Tutti', value: 'db' }
        ],
        defaultValue: 'db'
      },
      {
        label: 'Tabella',
        column: 'table_name',
        type: ColumnFilterType.STRING, defaultValue: ''
      }
    ];


    this.subject.subscribe(
      (msg) => {
        console.log(msg);

        // msg = JSON.parse(msg)
        console.log({ fn: 'subject.subscribe', debug: msg });

        if (!!msg.status) {
          this.manutenzionePaginator.updateStatus(msg.id, msg.status);
        }
        console.log('message received: ' + JSON.stringify(msg))
      }, // Called whenever there is a message from the server.
      (err) => console.log(err), // Called if at any point WebSocket API signals some kind of error.
      () => console.log('complete') // Called when connection is closed (for whatever reason).
    );

  }
  async startTruncate(ev: number) {
    console.log(await this.manutenzioneApiService.startTruncate(ev))
  }
  async startUpdating(ev: number) {
    const record = this.manutenzionePaginator.page.value.data.find(item => item.id === ev);
    if (record) {
      record.enableUpdate = true
      record.status = 0

    }
   // console.log(await this.manutenzioneApiService.startUpdating(ev))
    const data = !!this.inputWhereClause && this.inputWhereClause.find(o => o.id === ev) ? this.inputWhereClause.find(o => o.id === ev) : { id: ev, whereClause: '1=1' }
    console.log(data);

    this.subject.next(data);
    console.log('finit');
  }
  async getWhere(values: { input: any, id: number }) {
    this.inputWhereClause = []
    this.inputWhereClause.push(
      {
        id: values.id,
        whereClause: values.input.target.value
      })
  }
  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  public toggleSidebar(name): void {
    console.log(name);

    this._fuseSidebarService.getSidebar(name).toggleOpen();
  }

  public onFiltersChanged(queries: any): void {
    console.log(queries);
    this.manutenzionePaginator.filterData(queries)
    //this._fuseSidebarService.getSidebar().toggleOpen();
  }
}
